/* eslint-disable @typescript-eslint/no-explicit-any */
import { keyframes, styled } from 'styled-components'

import { Size } from '@/shared/model/common'

import { InputErrorDefault, InputLabelDefault } from './InputDefault.styled'

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`

export const InputWrapper = styled.div<{ $size: Size }>`
  position: relative;
  margin: 12px;
  min-height: 3.5rem;

  & > span {
    display: flex;
    align-items: center;
    column-gap: 15px;
    min-height: 21px;
    ${(props) => props.$size === 'sm' && `min-height: 18px;`}
    ${(props) => props.$size === 'lg' && `min-height: 26px;`}
  }
`

export const Input = styled.input<{ $size: Size }>`
  display: block;
  width: 21px;
  height: 21px;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s;
  background: ${(props: any) => props.theme.colors.backgroundFields};
  border-radius: ${(props: any) => props.theme.borderRadius.xsm};
  -webkit-box-shadow: ${(props: any) => props.theme.shadows.lg};
  -moz-box-shadow: ${(props: any) => props.theme.shadows.lg};
  box-shadow: ${(props: any) => props.theme.shadows.lg};
  ${(props) => props.$size === 'sm' && `height: 18px; width: 18px;`}
  ${(props) => props.$size === 'lg' && `height: 26px; width: 26px;`}

  &:checked {
    & + svg {
      --a: 16.1 86.12;
      --o: 102.22;
    }
  }

  &:hover {
    -webkit-box-shadow: ${(props: any) => props.theme.shadows.xl};
    -moz-box-shadow: ${(props: any) => props.theme.shadows.xl};
    box-shadow: ${(props: any) => props.theme.shadows.xl};
  }

  &:focus-within {
    outline: 2px solid ${(props: any) => props.theme.colors.primary};
  }
`

export const Svg = styled.svg<{ $rtl: boolean }>`
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: ${(props: any) => props.theme.colors.primary};
  position: absolute;
  top: 0;
  ${(props) => (props.$rtl ? 'right: 0;' : 'left: 0;')}
  width: 21px;
  height: 21px;
  transform: scale(1) translateZ(0);
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition:
    stroke-dasharray 0.2s,
    stroke-dashoffset 0.2s;

  ${Input}:checked + & {
    animation: ${bounce} 0.2s linear forwards 0.4s;
  }
`

export const InputLabel = styled(InputLabelDefault)`
  margin-bottom: unset;
  margin-left: unset;
  font-size: 1rem;
`

export const InputError = styled(InputErrorDefault)`
  min-height: 1.5rem;
`
