import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import { styled } from 'styled-components'

import { ILogoSVGMotion } from './LoaderLogo'

const TRANISTION_EASE = 'linear'

export const LogoLoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const LogoSVGMotion = ({
  duration = 3,
  stroke = 'white',
  size = 425,
  dim = 'width',
}: ILogoSVGMotion) => {
  const [animateShow, setAnimateShow] = useState<boolean>(false)
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true)
  const durationCalc = duration / 12

  const transitionForward = Array.from({ length: 12 }, (_, i) => ({
    duration: durationCalc,
    delay: i * durationCalc,
    ease: TRANISTION_EASE,
  }))

  const transitionBackward = Array.from({ length: 12 }, (_, i) => ({
    duration: durationCalc,
    delay: duration - i * durationCalc + 1,
    ease: TRANISTION_EASE,
  }))

  const variants = {
    hidden: { pathLength: 0, opacity: 0 },
    show: { pathLength: 1, opacity: 1 },
  }

  const calcWidth = useMemo(() => {
    return (size * 425) / 282
  }, [size])

  const calcHeight = useMemo(() => {
    return (size * 282) / 425
  }, [size])

  const width = useMemo(() => (dim === 'width' ? size : calcWidth), [calcWidth, dim, size])
  const height = useMemo(() => (dim === 'height' ? size : calcHeight), [calcHeight, dim, size])

  const handleChangeDirection = (isStart: boolean) => {
    if (isFirstTime) {
      setIsFirstTime(false)
    }
    if (animateShow && !isStart) {
      setAnimateShow(false)
    }
    if (!animateShow && isStart) {
      setAnimateShow(true)
    }
  }

  useEffect(() => {
    setAnimateShow(true)
  }, [])

  return (
    <motion.svg
      width={width}
      height={height}
      viewBox='0 0 425 282'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      initial='hidden'
      animate={animateShow ? 'show' : 'hidden'}
    >
      <motion.path
        d='M358.502 272H76'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[0] : transitionBackward[0]}
        onAnimationComplete={() => handleChangeDirection(true)}
      />
      <motion.path
        d='M76.8306 271.685C23.33 271.685 -39.1743 164.686 70.8293 112.186'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[1] : transitionBackward[1]}
      />
      <motion.path
        d='M71 111.5C71 85.8076 84.1828 60.169 104.723 41'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[2] : transitionBackward[2]}
      />
      <motion.path
        d='M104 41.6853C145.817 2.65959 218.129 -9.55134 271.78 59.1854'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[3] : transitionBackward[3]}
      />
      <motion.path
        d='M272 58.9245C272 58.9245 350.351 35.9248 359 118.925'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[4] : transitionBackward[4]}
      />
      <motion.path
        d='M359 119C407.5 119 456.997 227.5 359 271.5'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[5] : transitionBackward[5]}
      />
      <motion.path
        d='M105 41.763C204.777 12.6508 241.777 161.763 124.777 169.763'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[6] : transitionBackward[6]}
      />
      <motion.path
        d='M122.329 167.685C122.329 175.969 115.613 182.685 107.329 182.685C99.0448 182.685 92.3291 175.969 92.3291 167.685C92.3291 159.401 99.0448 152.685 107.329 152.685C115.613 152.685 122.329 159.401 122.329 167.685Z'
        stroke={stroke}
        strokeWidth='17'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[7] : transitionBackward[7]}
      />
      <motion.path
        d='M272.003 60C287.502 86.3333 304.5 151 249 201'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[8] : transitionBackward[8]}
      />
      <motion.circle
        cx='232.829'
        cy='215.185'
        r='17'
        stroke={stroke}
        strokeWidth='18'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[9] : transitionBackward[9]}
      />
      <motion.path
        d='M359.503 119C359.335 131.333 353.8 163.2 341 180'
        stroke={stroke}
        strokeWidth='19'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[10] : transitionBackward[10]}
      />
      <motion.circle
        cx='329.829'
        cy='199.185'
        r='17'
        stroke={stroke}
        strokeWidth='18'
        strokeLinecap='round'
        variants={variants}
        transition={animateShow ? transitionForward[11] : transitionBackward[11]}
        onAnimationComplete={() => handleChangeDirection(false)}
      />
    </motion.svg>
  )
}
