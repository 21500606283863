export const profile = {
  title: 'Profile',
  firstName: 'First Name',
  enterFirstName: 'Enter your first name...',
  lastName: 'Last Name',
  enterLastName: 'Enter your last name...',
  labelEmail: 'E-mail',
  enterEmail: 'Enter e-mail...',
  phoneNumber: 'Phone Number',
  enterPhoneNumber: '+48 573 943 997',
  labelCompanyName: 'Company Name',
  enterCompanyName: 'Enter company name...',
  companyVatId: 'Tax ID',
  enterCompanyVatId: 'Enter Tax ID...',
  customerTag: 'Company Tag',
  enterCustomerTag: 'Enter company tag...',
  save: 'Save',
}
