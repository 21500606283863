import { FloatingArrow } from '@floating-ui/react'
import { styled } from 'styled-components'

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`

export const TooltipContent = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  box-shadow: ${(props) => props.theme.shadows.sm};
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1002;
`

export const TooltipArrow = styled(FloatingArrow)`
  fill: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.shadows.sm};
  z-index: 1001;
`
