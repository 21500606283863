import { Shimmer, SkeletonBar, SkeletonWrapper } from './skeletonElements'

export interface ISkeleton {
  repeat?: number
  fullWidth?: boolean
  height?: number
  width?: number
}

const Skeleton = ({ height = 50, fullWidth = false, width = 500, repeat = 5 }: ISkeleton) => {
  const skeletons = []
  for (let i = 0; i < repeat; i++) {
    skeletons.push(
      <SkeletonBar
        key={i}
        $height={height}
        $fullWidth={fullWidth}
        $width={width}
      >
        <Shimmer
          fullWidth={fullWidth}
          width={width}
        />
      </SkeletonBar>,
    )
  }

  return <SkeletonWrapper>{skeletons}</SkeletonWrapper>
}

export default Skeleton
