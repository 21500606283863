import { useMutation } from '@tanstack/react-query'

import { api } from './api'

export interface IProfileReq {
  id: string
  phoneNumber: string
  companyName: string
}

export interface IProfileRes {
  customer: {
    id: string
    customerTag: string
    companyName: string
    companyVatId: string
    countryCode: string
    phoneNumber: string
    firstName: string
    lastName: string
    email: string
    createdAt: Date
    updatedAt: Date
    verifiedAt: Date
  }
}

export const useProfileMutation = () => {
  return useMutation({
    mutationKey: ['profile'],
    mutationFn: async ({ phoneNumber, companyName, id }: IProfileReq) => {
      const { data } = await api.patch<IProfileRes>(`/portal/customer/${id}`, {
        phoneNumber,
        companyName,
      })

      return data
    },
  })
}
