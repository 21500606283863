import { useTranslation } from 'react-i18next'
import { MdErrorOutline } from 'react-icons/md'

import Button from '@/shared/components/button/Button'

import { ErrorWrapper } from './errorElements'

const ErrorView = () => {
  const { t } = useTranslation()
  return (
    <ErrorWrapper>
      <MdErrorOutline />
      <h1>{t('common.errorHasOccured') ?? 'Error'} 😥</h1>
      <Button
        label={t('common.returnHome')}
        onClick={() => (window.location.href = '/')}
      />
    </ErrorWrapper>
  )
}

export default ErrorView
