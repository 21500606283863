import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const LoginWrapper = styled.div`
  display: flex;
  width: 100dvw;
  background-color: ${(props) => props.theme.colors.background};
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  height: 100%;
`

export const LoginLeftSide = styled(motion.section)`
  display: flex;
  flex-direction: column;
  width: 50dvw;
  height: 100%;
  min-height: 750px;

  @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
    display: none;
  }
`

export const LoginRightSide = styled(motion.section)`
  display: flex;
  flex-direction: column;
  width: 50dvw;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary400};
  align-items: center;
  justify-content: center;
  z-index: 2;
  min-height: 750px;

  @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    justify-content: unset;
    row-gap: 48px;
  }

  > div.lang-select {
    position: absolute;
    top: 0;
    right: 24px;
    z-index: 100;

    @media (max-width: ${(props) => props.theme.breakpoint.md}) {
      top: 80px;
    }
  }

  > svg {
    position: absolute;
    width: 40dvw;
    height: auto;
    overflow: visible;
    max-height: 50dvh;
    min-height: 700px;
    min-width: 560px;
    display: block;
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
      top: calc(80px + 48px + 48px);
      display: block;
      position: absolute;
      height: 80%;
      width: auto;
      overflow: visible;
      max-width: 100%;
      box-sizing: border-box;
      margin-left: 1dvw;
      min-height: 400px;
      min-width: 600px;
    }

    @media (max-height: ${(props) => props.theme.breakpoint.s}) {
    }
  }

  form {
    display: flex;
    width: 30dvw;
    height: calc(665 / 749 * 30dvw);
    max-height: 50dvh;
    z-index: 10;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5dvh;
    margin-right: 50px;
    min-height: 500px;
    @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
      height: 80%;
      width: 300px;
      min-height: 400px;
      margin-right: unset;
    }

    > h1 {
      color: ${(props) => props.theme.colors.whiteText};
      font-size: 2.5rem;
    }

    > div:first-of-type {
      margin-top: 0;
      margin-bottom: 0;
    }

    > div > label {
      color: ${(props) => props.theme.colors.whiteText};
    }

    > span {
      margin-top: 8px;
      width: 300px;
      text-align: end;
    }

    > span a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.whiteText};
    }

    @media (max-width: ${({ theme }) => theme.breakpoint.xxl}) {
      > span,
      > button,
      > div {
        width: 200px;
      }
    }
  }
`

export const LogoContainer = styled.div`
  padding: 24px;
  display: flex;
  font-size: 1.1rem;
  width: 100%;
  height: 80px;
  flex-grow: 0;
  flex-shrink: 0;

  img {
    width: auto;
    height: 80px;
  }

  h1 {
    color: ${(props) => props.theme.colors.primary300};
    font-weight: 300;
    margin-bottom: 2px;
    margin-top: auto;
    margin-left: 16px;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px - 12.5dvw * 0.67);
  justify-content: center;
  align-items: center;

  img {
    width: 25dvw;
    height: auto;
    max-width: 500px;
    max-height: calc(500px * 0.67);
  }

  h1 {
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.primary400};
    margin: 15px 0 0;

    &:last-child {
      margin: 0.3rem 0 0;
    }
  }
`

export const RegisterButton = styled(motion.div)`
  position: fixed;
  width: 10dvw;
  height: 2rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.whiteText};
  background-color: ${(props) => props.theme.colors.primary400};
  bottom: 0;
  right: 48dvw;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 500;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    left: calc(-1rem - 9px);
    top: 0;
    border-left: calc(1rem + 10px) solid transparent;
    border-top: calc(1rem + 10px) solid ${(props) => props.theme.colors.primary400};
    border-bottom: calc(1rem + 10px) solid ${(props) => props.theme.colors.primary400};
  }
`

export const CoverBlock = styled(motion.div)`
  position: absolute;
  width: 50dvw;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary400};
  right: 0;
  overflow: hidden;
  min-height: 750px;
`

export const LoginHeader = styled(motion.div)`
  padding: 24px;
  display: flex;
  font-size: 1.1rem;
  width: 100%;
  height: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 0;

  img {
    margin-left: 24px;
    width: auto;
    height: 80px;

    @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
      height: 60px;
    }
  }

  h1 {
    color: ${(props) => props.theme.colors.whiteText};
    font-weight: 300;
    margin-bottom: 2px;
    margin-top: auto;
    margin-left: 16px;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.xl}) {
    display: none;
  }
`
