import { styled } from 'styled-components'

export const ProductsWrapper = styled.div`
  display: flex;
  row-gap: 5dvh;
  flex-direction: column;
  align-items: center;

  .my-products.no-products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`
