import { Link } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { AiFillProduct } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FaSignOutAlt } from 'react-icons/fa'
import { GrLicense } from 'react-icons/gr'

import { useAuth } from '@/auth'
import { router } from '@/main'

import LogoText from '@assets/logo_text.webp'

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const logoVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.3,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export const NavbarNavigation = ({ toggle }: { toggle: (isOpen: boolean) => void }) => {
  const { t } = useTranslation()
  const { logout } = useAuth()

  return (
    <>
      <motion.div
        className='navbar-logo'
        variants={logoVariants}
        whileTap={{ scale: 0.95 }}
        onClick={() => {
          router.navigate({ to: '/' })
          toggle(false)
        }}
      >
        <img
          src={LogoText}
          alt='logo'
        />
      </motion.div>
      <motion.ul variants={variants}>
        <motion.li
          variants={itemVariants}
          whileTap={{ scale: 0.95 }}
          onClick={() => toggle(false)}
        >
          <AiFillProduct />
          <Link to='/products'>{t('navbar.products')}</Link>
        </motion.li>
        <motion.li
          variants={itemVariants}
          whileTap={{ scale: 0.95 }}
          onClick={() => toggle(false)}
        >
          <GrLicense />
          <Link to='/myProducts'>{t('navbar.myProducts')}</Link>
        </motion.li>
        <motion.li
          variants={itemVariants}
          whileTap={{ scale: 0.95 }}
          onClick={() => toggle(false)}
        >
          <CgProfile />
          <Link to='/profile'>{t('navbar.profile')}</Link>
        </motion.li>
        <motion.li
          variants={itemVariants}
          whileTap={{ scale: 0.95 }}
          onClick={() => toggle(false)}
        >
          <FaSignOutAlt />
          <div
            onClick={() => {
              toggle(false)
              logout()
            }}
            aria-hidden='true'
          >
            {t('navbar.signOut')}
          </div>
        </motion.li>
      </motion.ul>
    </>
  )
}
