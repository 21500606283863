import { styled } from 'styled-components'

export const FooterWrapper = styled.footer`
  display: flex;
  position: relative;
  bottom: 0;
  left: 0;
  flex-direction: column;
  overflow: hidden;
  z-index: -1;
  margin-top: -250px;
  width: 100dvw;
  max-width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
    margin-top: 0;
  }

  > svg {
    width: 100%;
    height: auto;
  }
`

export const FooterBlock = styled.div`
  display: flex;
  max-width: 100vw;
  height: 33dvh;
  background-color: ${(props) => props.theme.colors.primary400};
  justify-content: space-between;
  column-gap: 5rem;
  padding-inline: 5vw;

  @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    height: 100%;
    padding-bottom: 1rem;
    row-gap: 2rem;
  }

  > div {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    width: calc(33vw - 10rem / 3 - 10vw / 3);
    justify-content: center;
    align-items: center;

    img {
      height: 100px;
      width: auto;
      min-width: 250px;
      min-height: 60px;

      @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
        height: 60px;
        min-width: unset;
        min-height: unset;
      }
    }

    p,
    a,
    h2,
    svg {
      text-decoration: none;
      color: ${(props) => props.theme.colors.whiteText};
    }

    @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
      width: calc(100% - 32px);
    }
  }

  .col1 {
    align-items: flex-start;
    @media (max-width: ${(props) => props.theme.breakpoint.xl}) {
      align-items: center;
      text-align: center;
    }
  }

  .col2 {
    svg {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }

  .col3 {
    row-gap: 0.5rem;
    font-size: 1.1rem;
  }
`

export const FooterCopyright = styled.div`
  display: flex;
  width: 100%;
  height: 1rem;
  font-size: 0.6rem;
  background-color: ${(props) => props.theme.colors.primary400};
  color: ${(props) => props.theme.colors.whiteText};
  justify-content: center;
`
