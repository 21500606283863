import { motion } from 'framer-motion'
import { css, styled } from 'styled-components'

export const HomeWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  height: 60dvh;
  background: ${(props) => props.theme.colors.bgGlass};
  border-radius: ${(props) => props.theme.borderRadius.md};
  box-shadow: ${(props) => props.theme.shadows.md};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 32px;

  @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

const blockCommon = css`
  box-shadow: ${(props) => props.theme.shadows.md};
  border-radius: ${(props) => props.theme.borderRadius.md};
  cursor: pointer;
  padding-left: 32px;
  padding-right: 32px;
  padding: 8px 32px;
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
    flex-direction: row;
    height: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
    flex-direction: column;
  }

  h2,
  p {
    display: flex;
    color: ${(props) => props.theme.colors.whiteText};
    max-width: 60ch;
  }

  h2 {
    word-break: break-word;
  }

  div {
    overflow: hidden;
  }

  div.home.home-icon {
    width: calc(100% - 60ch - 24px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 150px;

    svg {
      color: ${(props) => props.theme.colors.whiteText};
      height: 80%;
      width: auto;
    }

    @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
      width: 100%;
      justify-content: center;

      svg {
        width: 50%;
        height: auto;
      }
    }
  }

  div.home-icon.home-bento-vertical {
    width: 100%;
    height: 40%;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;

    svg {
      color: ${(props) => props.theme.colors.whiteText};
      height: 100%;
      width: auto;
    }

    @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
      height: unset;
      width: calc(100% - 60ch - 24px);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-width: 150px;
      padding-bottom: unset;

      svg {
        color: ${(props) => props.theme.colors.whiteText};
        height: 80%;
        width: auto;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
      width: 100%;
      justify-content: center;

      svg {
        width: 50%;
        height: auto;
      }
    }
  }
`

export const ProductsBlock = styled(motion.div)`
  grid-area: 1 / 1 / 3 / 3;
  ${blockCommon}
  background-color: ${(props) => props.theme.colors.primary100};
`

export const MyProductsBlock = styled(motion.div)`
  grid-area: 3 / 1 / 5 / 3;
  ${blockCommon}
  background-color: ${(props) => props.theme.colors.primary400};
`

export const ProfileBlock = styled(motion.div)`
  grid-area: 1 / 3 / 5 / 4;
  ${blockCommon}
  background-color: ${(props) => props.theme.colors.primary300};
  flex-direction: column;
`
