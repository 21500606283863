import { styled } from 'styled-components'

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #afafafc0;
  padding: 64px 16px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  background: ${(props) => props.theme.colors.bgGlass};
  box-shadow: ${(props) => props.theme.shadows.md};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: ${(props) => props.theme.breakpoint.md}) {
      width: 100%;
    }

    div.form-fields-wrapper.profile {
      display: flex;
      gap: 30px;
      width: 100%;

      @media (max-width: ${(props) => props.theme.breakpoint.md}) {
        flex-direction: column;
        gap: unset;
      }
    }
  }
`
