import 'react-toastify/dist/ReactToastify.css'
import '../index.css'

import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet, ScrollRestoration } from '@tanstack/react-router'
import { Bounce, ToastContainer } from 'react-toastify'
import { createGlobalStyle } from 'styled-components'

import { IAuthContext } from '@/AuthContext'
import getTheme from '@/shared/utils/getTheme'

export const Route = createRootRouteWithContext<{
  auth: IAuthContext
  queryClient: QueryClient
}>()({
  component: RootComponent,
})

function RootComponent() {
  const currentTheme = getTheme()

  const GlobalStyle = createGlobalStyle`
    body {
      font-family: Inter, sans-serif;
    }

    :root {
      --toastify-color-light: ${(props) => props.theme.complementaryColors.toastBackground};
      --toastify-color-dark: ${(props) => props.theme.complementaryColors.toastBackground};
      --toastify-color-info: ${(props) => props.theme.complementaryColors.info};
      --toastify-color-success: ${(props) => props.theme.complementaryColors.success};
      --toastify-color-warning: ${(props) => props.theme.complementaryColors.warning};
      --toastify-color-error: ${(props) => props.theme.complementaryColors.error};
      --toastify-color-transparent: rgb(255 255 255 / 70%);
    }
  `

  return (
    <>
      <GlobalStyle />
      <Outlet />
      <ToastContainer
        stacked
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={currentTheme}
        transition={Bounce}
      />
      <ScrollRestoration />
    </>
  )
}
