import { useForm } from '@tanstack/react-form'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { AsYouType } from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useProfileMutation } from '@/shared/api/profileQuery'
import Button from '@/shared/components/button/Button'
import InputText from '@/shared/components/input/InputText'
import { PageWrapper } from '@/shared/components/wrapper/PageWrapper'
import convertPhoneNumber from '@/shared/utils/convertPhoneNumber'
import useDimBreakpoints from '@/shared/utils/useDimBreakpoints'

import { ProfileWrapper } from './profileElements'
import { companyNameValidator, phoneNumberValidator } from './utils/validators'
import { useGetUserProfile } from '@/shared/api/customersQuery'
import LoadingWrapper from '@/shared/components/wrapper/LoadingWrapper'
import Skeleton from '@/shared/components/skeleton/Skeleton'

const Profile = () => {
  const { t } = useTranslation()
  const { md, xxl } = useDimBreakpoints()
  const { data: profile, isLoading } = useGetUserProfile()

  const { mutateAsync: profileUpdate } = useProfileMutation()

  const form = useForm({
    defaultValues: {
      customerTag: profile?.customerTag ?? '',
      companyName: profile?.companyName ?? '',
      companyVatId: profile?.companyVatId ?? '',
      phoneNumber:
        (profile?.phoneNumber && new AsYouType().input(`+${profile?.phoneNumber}`)) ?? '',
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      email: profile?.email ?? '',
    },
    onSubmit: ({ value }) => {
      if (!profile?.id) {
        toast.error(t('common.error.noAuth'))
        return null
      }

      const _value = { ...value }
      _value.phoneNumber = convertPhoneNumber(_value.phoneNumber)

      return toast.promise(
        profileUpdate({
          phoneNumber: _value.phoneNumber,
          companyName: _value.companyName,
          id: profile?.id,
        }),
        {
          pending: t('common.toast.pending'),
          success: t('common.toast.success'),
          error: t('common.toast.error'),
        },
      )
    },
    validatorAdapter: zodValidator(),
  })

  if (isLoading)
    return (
      <LoadingWrapper>
        <div className='loader loader-space' />
        <Skeleton
          fullWidth
          repeat={1}
        />
        <div className='loader loader-space' />
        <Skeleton
          fullWidth
          repeat={1}
          height={720}
        />
      </LoadingWrapper>
    )

  return (
    <PageWrapper>
      <h1 className='page-wrapper-gradient'>{t('profile.title')}</h1>
      <ProfileWrapper>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            form.handleSubmit()
          }}
        >
          <div className='profile form-fields-wrapper'>
            <div>
              <form.Field name='firstName'>
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.firstName')}
                      placeholder={t('profile.enterFirstName')}
                      isDisabled
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
              <form.Field name='lastName'>
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.lastName')}
                      placeholder={t('profile.enterLastName')}
                      isDisabled
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
              <form.Field name='email'>
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.labelEmail')}
                      placeholder={t('profile.enterEmail')}
                      isDisabled
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
              <form.Field name='customerTag'>
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.customerTag')}
                      placeholder={t('profile.enterCustomerTag')}
                      isDisabled
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
            </div>
            <div>
              <form.Field name='companyVatId'>
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.companyVatId')}
                      placeholder={t('profile.enterCompanyVatId')}
                      isDisabled
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
              <form.Field
                name='companyName'
                validators={{
                  onChange: companyNameValidator,
                }}
              >
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.labelCompanyName')}
                      placeholder={t('profile.enterCompanyName')}
                      error={field.state.meta.errors[0] as string}
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
              <form.Field
                name='phoneNumber'
                validators={{
                  onChange: phoneNumberValidator,
                }}
              >
                {(field) => (
                  <>
                    <InputText
                      id={field.name}
                      value={field.state.value}
                      onChange={(v: string) => field.handleChange(v)}
                      onBlur={field.handleBlur}
                      label={t('profile.phoneNumber')}
                      placeholder={t('profile.enterPhoneNumber')}
                      error={field.state.meta.errors[0] as string}
                      type='tel'
                      fullWidth={md ? true : false}
                      size={xxl ? 'md' : 'lg'}
                    />
                  </>
                )}
              </form.Field>
            </div>
          </div>

          <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
            {([canSubmit, isSubmitting]) => (
              <Button
                type='submit'
                btnType='secondary'
                isDisabled={!canSubmit}
                label={isSubmitting ? '...' : t('profile.save')}
                onClick={() => {
                  return null
                }}
                fullWidth={md ? true : false}
                size='lg'
                animations={['tap', 'hover', 'gradient']}
              />
            )}
          </form.Subscribe>
        </form>
      </ProfileWrapper>
    </PageWrapper>
  )
}

export default Profile
