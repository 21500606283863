import BasketWoman from '@assets/basket_woman.webp'
import LogoTextBlue from '@assets/logo_text_blue.webp'
import LogoText from '@assets/logo_text.webp'
import { useForm } from '@tanstack/react-form'
import { Link, useNavigate, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { motion } from 'framer-motion'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useAuth } from '@/auth'
import { Route } from '@/routes/login'
import { useLoginMutation } from '@/shared/api/authQuery'
import Button from '@/shared/components/button/Button'
import { LanguageSelector } from '@/shared/components/dropdown/LanguageSelector'
import InputText from '@/shared/components/input/InputText'

import { LoginBlob } from './components/LoginBlob'
import {
  CoverBlock,
  LoginHeader,
  LoginLeftSide,
  LoginRightSide,
  LoginWrapper,
  LogoContainer,
  RegisterButton,
  TitleContainer,
} from './loginElements'
import { emailValidator, passwordValidator } from './utils/validators'
import StaggerText from '@/shared/components/staggerText/StaggerText'
import Tooltip from '@/shared/components/tooltip/Tooltip'
import useDimBreakpoints from '@/shared/utils/useDimBreakpoints'

const fallback = '/' as const

const Login = (): JSX.Element => {
  const { t } = useTranslation()
  const router = useRouter()
  const auth = useAuth()
  const search = Route.useSearch()
  const { xxl } = useDimBreakpoints()
  const navigate = useNavigate({ from: '/login' })
  const [isInTransition, setIsInTransition] = useState<boolean>(false)
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true)
  const { mutateAsync: loginMutation } = useLoginMutation()

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    onSubmit: ({ value }) => {
      const { email, password } = value
      loginMutation({ email: email.toLowerCase(), password })
        .then((res) => {
          auth.login({ email: value.email, sessionToken: res.sessionToken, refreshToken: '' })
          toast.success(t('login.toast.success'))
        })
        .then(() => {
          router.invalidate()
        })
        .then(() => {
          navigate({ to: search.redirect ?? fallback })
        })
        .catch((res) => {
          if (res.response.data.code === 16 || res.response.data.code === 2) {
            toast.error(t('login.toast.badCreds'))
          } else {
            toast.error(t('login.toast.error'))
          }
        })
    },
    validatorAdapter: zodValidator(),
  })

  useLayoutEffect(() => {
    if (auth.user && search.redirect) {
      if (search.redirect) {
        router.history.push(search.redirect)
      } else {
        router.history.push('/')
      }
    }
  }, [search.redirect, router.history, auth.user])

  const handleRegisterRedirect = useCallback(() => {
    if (isFirstLoad) {
      setFirstLoad(false)
    } else {
      navigate({ to: '/register' })
    }
  }, [isFirstLoad, navigate])

  const loginForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <h1>{t('login.signIn')}</h1>
        <form.Field
          name='email'
          validators={{
            onChange: emailValidator,
          }}
        >
          {(field) => (
            <>
              <InputText
                id={field.name}
                value={field.state.value}
                onChange={(v: string) => field.handleChange(v)}
                onBlur={field.handleBlur}
                label={t('login.labelEmail')}
                placeholder={t('login.enterEmail')}
                error={field.state.meta.errors[0] as string}
                size={xxl ? 'md' : 'lg'}
              />
            </>
          )}
        </form.Field>
        <form.Field
          name='password'
          validators={{
            onChange: passwordValidator,
          }}
        >
          {(field) => (
            <>
              <InputText
                id={field.name}
                value={field.state.value}
                onChange={(v: string) => field.handleChange(v)}
                onBlur={field.handleBlur}
                label={t('login.labelPassword')}
                placeholder={t('login.enterPassword')}
                error={field.state.meta.errors[0] as string}
                type='password'
                size={xxl ? 'md' : 'lg'}
              />
            </>
          )}
        </form.Field>
        <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
          {([canSubmit, isSubmitting]) => (
            <Button
              type='submit'
              btnType='secondary'
              isDisabled={!canSubmit}
              label={isSubmitting ? '...' : t('login.btnSignIn')}
              onClick={() => {
                return null
              }}
              size={xxl ? 'md' : 'lg'}
              animations={['tap', 'hover', 'gradient']}
            />
          )}
        </form.Subscribe>
        <span>
          <Tooltip content={t('common.soon')}>
            <Link to={'/login'}>{t('login.forgotPassword')}</Link>
          </Tooltip>{' '}
          {/* TODO change to /recovery */}
        </span>
        <span>
          <Link to={'/register'}>{t('login.goToSignUp')}</Link>
        </span>
      </form>
    )
  }

  return (
    <LoginWrapper>
      <LoginLeftSide
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <LogoContainer>
          <img
            src={LogoTextBlue}
            alt='logo'
          />
        </LogoContainer>
        <TitleContainer>
          <motion.div
            animate={{
              x: [0, 5, 10, -10, -5, 0],
              rotate: [0, -1.5, -3, 0],
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              repeatType: 'loop',
              ease: 'easeInOut',
            }}
          >
            <img
              src={BasketWoman}
              alt='login_img'
            />
          </motion.div>

          <h1>
            <StaggerText
              text={t('login.title')}
              delay={0.2}
              split={''}
              staggerSpeed={0.1}
              delayDivider={20}
            />
          </h1>
          <h1>
            <StaggerText
              text={t('login.title2')}
              delay={1.1}
              split={''}
              staggerSpeed={0.1}
              delayDivider={20}
            />
          </h1>
        </TitleContainer>
        <RegisterButton
          whileHover={{
            x: '-1.9dvw',
            transition: { duration: 1 },
          }}
          initial={{ x: '8vw' }}
          animate={isInTransition ? { x: '-50vw' } : { x: '0vw' }}
          transition={{ duration: 0.5 }}
          onClick={() => setIsInTransition(true)}
        >
          {t('login.goToSignUp')}
        </RegisterButton>
      </LoginLeftSide>
      <CoverBlock
        animate={isInTransition ? { width: '100dvw' } : { width: '50dvw' }}
        transition={{ duration: 0.5 }}
        onAnimationComplete={() => handleRegisterRedirect()}
      />
      <LoginRightSide
        initial={{ opacity: 0 }}
        animate={isInTransition ? { opacity: 0 } : { opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className='lang-select'>
          <LanguageSelector />
        </div>

        <LoginHeader
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={LogoText}
            alt='logo'
          />
        </LoginHeader>
        {loginForm()}
        <LoginBlob />
      </LoginRightSide>
    </LoginWrapper>
  )
}

export default Login
