import { FaBuilding, FaPerson, FaSquareCheck } from 'react-icons/fa6'

import { Circle, StyledLi, StyledOl } from './stepperElements'

export type Steps = 1 | 2 | 3

interface IStepper {
  activeStep: Steps
  setStep: (step: Steps) => void
}

export const Stepper = ({ activeStep, setStep }: IStepper) => {
  return (
    <StyledOl>
      <StyledLi $active={activeStep >= 1}>
        <Circle
          $active={activeStep >= 1}
          onClick={() => setStep(1)}
        >
          <FaBuilding />
        </Circle>
      </StyledLi>
      <StyledLi $active={activeStep >= 2}>
        <Circle
          $active={activeStep >= 2}
          onClick={() => setStep(2)}
        >
          <FaPerson />
        </Circle>
      </StyledLi>
      <StyledLi $active={activeStep === 3}>
        <Circle
          $active={activeStep === 3}
          onClick={() => setStep(3)}
        >
          <FaSquareCheck />
        </Circle>
      </StyledLi>
    </StyledOl>
  )
}
