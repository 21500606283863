import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import Home from '@/views/home'

export const Route = createFileRoute('/_auth/')({
  validateSearch: z.object({
    redirect: z.string().optional(),
  }),
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({ to: '/login' })
    }
  },
  component: Home,
})
