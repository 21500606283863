import { useEffect, useState } from 'react'

import { Size } from '@/shared/model/common'

import { SwitchHandle, SwitchHandleWrapper, SwitchWrapper } from './switchElements'

export interface ISwitch {
  onChange: (isSwitched: boolean) => void
  value: boolean
  label?: string
  required?: boolean
  size?: Size
  id?: string
  error?: string
  labelPosition?: 'left' | 'top'
}

const Switch = ({
  onChange,
  value,
  size = 'md',
  id,
  label,
  required,
  error,
  labelPosition = 'left',
}: ISwitch) => {
  const [isSwitched, setSwitch] = useState<boolean>(value)

  useEffect(() => {
    setSwitch(value)
  }, [value])

  const handleSwitch = () => {
    onChange(!isSwitched)
  }

  return (
    <SwitchWrapper
      $size={size}
      $labelPosition={labelPosition}
    >
      <span>
        {label}
        {required && '*'}
        <SwitchHandleWrapper
          $isOn={isSwitched}
          $size={size}
          onClick={handleSwitch}
          id={id}
        >
          <SwitchHandle $size={size} />
        </SwitchHandleWrapper>
      </span>
      <span>{error}</span>
    </SwitchWrapper>
  )
}

export default Switch
