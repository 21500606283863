import { useTranslation } from 'react-i18next'
import { BsQuestionCircle } from 'react-icons/bs'

import Button from '@/shared/components/button/Button'

import { NotFoundWrapper } from './notFoundElements'

const NotFoundView = () => {
  const { t } = useTranslation()
  return (
    <NotFoundWrapper>
      <BsQuestionCircle />
      <h1>{t('common.errorNotFound') ?? 'Error'} 🤔</h1>
      <Button
        label={t('common.returnHome')}
        onClick={() => (window.location.href = '/')}
      />
    </NotFoundWrapper>
  )
}

export default NotFoundView
