import { motion } from 'framer-motion'
import { GrFormNextLink } from 'react-icons/gr'
import { styled } from 'styled-components'

export const RegisterWrapper = styled.div`
  position: fixed;
  width: 100dvw;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.primary400};
  min-height: 650px;
  /* min-width: 850px; */
  top: 0;
  height: 100%;
`

export const RegisterHeader = styled(motion.div)`
  padding: 24px;
  display: flex;
  font-size: 1.1rem;
  width: calc(100% - 48px);
  height: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 0;
  justify-content: space-between;

  > span {
    display: flex;
  }

  img {
    width: auto;
    height: 80px;

    @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
      height: 60px;
    }
  }

  h1 {
    color: ${(props) => props.theme.colors.whiteText};
    font-weight: 300;
    margin-bottom: 2px;
    margin-top: auto;
    margin-left: 16px;
  }

  > div.lang-select {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 24px;

    @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
      top: 80px;
    }
  }
`

export const RegisterContent = styled(motion.div)`
  display: flex;
  width: 100dvw;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  row-gap: 64px;
  margin-top: 16px;
  min-height: 1000px;
  height: 100%;

  > h1 {
    margin-top: 0;
    color: ${(props) => props.theme.colors.whiteText};
    font-size: 2.5rem;
    z-index: 10;
  }

  > svg {
    top: calc(80px + 80px);
    display: block;
    position: absolute;
    height: 80%;
    width: auto;
    overflow: visible;
    max-width: 100%;
    box-sizing: border-box;
    margin-left: 1dvw;
    min-height: 750px;
    min-width: 750px;
  }

  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    width: 300px;
    z-index: 2;
    min-height: 650px;

    > div > div {
      width: 300px;
      margin-top: 0;
    }

    > div > div:last-of-type {
      margin-bottom: 0;
    }

    > div > div > label {
      color: ${(props) => props.theme.colors.whiteText};
    }

    > span {
      margin-top: 0.5rem;
      margin-top: 8px;
      width: 300px;
      text-align: end;
      @media (min-width: ${(props) => props.theme.breakpoint.xxl}) {
        display: none;
      }
      > a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.whiteText};
        cursor: pointer;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
    margin-top: 80px;
  }
`

export const RegisterStep = styled.div<{ $show: boolean }>`
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  flex-direction: column;

  > div:last-of-type > span {
    display: flex;
    justify-content: flex-end;

    > label {
      color: ${(props) => props.theme.colors.whiteText};
    }
  }
`

export const NextIcon = styled(GrFormNextLink)`
  font-size: 1.5rem;
`

export const LoginButton = styled(motion.div)`
  position: fixed;
  width: 10dvw;
  height: 2rem;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.primary400};
  background-color: ${(props) => props.theme.colors.background};
  bottom: 0;
  left: -2dvw;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 500;
  text-align: right;
  justify-content: flex-end;

  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    right: calc(-1rem - 9px);
    top: 0;
    border-right: calc(1rem + 10px) solid transparent;
    border-top: calc(1rem + 10px) solid ${(props) => props.theme.colors.background};
    border-bottom: calc(1rem + 10px) solid ${(props) => props.theme.colors.background};
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xxl}) {
    display: none;
  }
`

export const CoverBlock = styled(motion.div)`
  position: absolute;
  width: 0;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  left: 0;
  overflow: hidden;
  z-index: 10;
  min-height: 750px;
`

export const RegisterSuccess = styled.div`
  z-index: 5;
  color: ${(props) => props.theme.colors.whiteText};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  width: 350px;
  z-index: 2;
  min-height: 650px;
  margin-top: 100px;

  > div {
    width: 5rem;
    height: auto;
    > svg {
      font-size: 5rem;
    }
  }

  > p {
    font-size: 1.8rem;
    text-align: center;
  }
`
