import { THEME_LS } from '@/config'

import { Theme } from '../model/common'

const getTheme = () => {
  const theme = localStorage.getItem(THEME_LS)
  if (!theme) {
    // const mq = window.matchMedia('(prefers-color-scheme: dark)')
    // localStorage.setItem('theme', mq.matches ? 'dark' : 'light')
    localStorage.setItem(THEME_LS, 'light')
    return localStorage.getItem(THEME_LS) as Theme
  }
  if (theme === 'light') {
    return localStorage.getItem(THEME_LS) as Theme
  }
  if (theme === 'dark') {
    localStorage.setItem(THEME_LS, 'light') //TODO remove after implementation of dark mode
    return localStorage.getItem(THEME_LS) as Theme
  }
  return theme as Theme
}

export default getTheme
