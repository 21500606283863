import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { AiFillProduct } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { GrLicense } from 'react-icons/gr'

import { useAuth } from '@/auth'
import { router } from '@/main'
import { PageWrapper } from '@/shared/components/wrapper/PageWrapper'

import { HomeWrapper, MyProductsBlock, ProductsBlock, ProfileBlock } from './homeElements'

const Home = () => {
  const { t } = useTranslation()
  const { decodedUser } = useAuth()
  const navigate = useNavigate({ from: '/' })

  return (
    <PageWrapper>
      <h1 className='page-wrapper-gradient'>
        {t('home.title')}
        {decodedUser?.firstName && ` ${decodedUser?.firstName}`}!
      </h1>
      <HomeWrapper>
        <ProductsBlock
          whileTap={{ scale: 0.99 }}
          whileHover={{ scale: 1.01 }}
          onClick={() => navigate({ to: '/products' })}
          onHoverStart={() => router.preloadRoute({ to: '/products' })}
        >
          <div>
            <h2>{t('home.products.title')}</h2>
            <p>{t('home.products.desc')}</p>
          </div>
          <div className='home home-icon'>
            <AiFillProduct />
          </div>
        </ProductsBlock>
        <MyProductsBlock
          whileTap={{ scale: 0.99 }}
          whileHover={{ scale: 1.01 }}
          onClick={() => navigate({ to: '/myProducts' })}
          onHoverStart={() => router.preloadRoute({ to: '/myProducts' })}
        >
          <div>
            <h2>{t('home.myProducts.title')}</h2>
            <p>{t('home.myProducts.desc')}</p>
          </div>
          <div className='home home-icon'>
            <GrLicense />
          </div>
        </MyProductsBlock>
        <ProfileBlock
          whileTap={{ scale: 0.99 }}
          whileHover={{ scale: 1.01 }}
          onClick={() => navigate({ to: '/profile' })}
          onHoverStart={() => router.preloadRoute({ to: '/profile' })}
        >
          <div className='home home-bento-vertical'>
            <h2>
              {t('home.profile.title')}
              {decodedUser?.companyName &&
                decodedUser?.customerTag &&
                `: ${decodedUser?.companyName} - ${decodedUser?.customerTag}`}
            </h2>
            <p>{t('home.profile.desc')}</p>
          </div>
          <div className='home home-icon home-bento-vertical'>
            <CgProfile />
          </div>
        </ProfileBlock>
      </HomeWrapper>
    </PageWrapper>
  )
}

export default Home
