export const Wave = () => (
  <svg
    id='visual'
    viewBox='0 300 900 300'
    width='900'
    height='300'
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
  >
    <path
      d='M0 374L18.8 377.5C37.7 381 75.3 388 112.8 389.3C150.3 390.7 187.7 386.3 225.2 390.8C262.7 395.3 300.3 408.7 337.8 412.3C375.3 416 412.7 410 450.2 400.2C487.7 390.3 525.3 376.7 562.8 381.2C600.3 385.7 637.7 408.3 675.2 419.8C712.7 431.3 750.3 431.7 787.8 428.2C825.3 424.7 862.7 417.3 881.3 413.7L900 410L900 601L881.3 601C862.7 601 825.3 601 787.8 601C750.3 601 712.7 601 675.2 601C637.7 601 600.3 601 562.8 601C525.3 601 487.7 601 450.2 601C412.7 601 375.3 601 337.8 601C300.3 601 262.7 601 225.2 601C187.7 601 150.3 601 112.8 601C75.3 601 37.7 601 18.8 601L0 601Z'
      fill='#00a5e0'
    ></path>
    <path
      d='M0 455L18.8 452.8C37.7 450.7 75.3 446.3 112.8 439.8C150.3 433.3 187.7 424.7 225.2 427.7C262.7 430.7 300.3 445.3 337.8 449C375.3 452.7 412.7 445.3 450.2 440.8C487.7 436.3 525.3 434.7 562.8 438.5C600.3 442.3 637.7 451.7 675.2 454.8C712.7 458 750.3 455 787.8 452.5C825.3 450 862.7 448 881.3 447L900 446L900 601L881.3 601C862.7 601 825.3 601 787.8 601C750.3 601 712.7 601 675.2 601C637.7 601 600.3 601 562.8 601C525.3 601 487.7 601 450.2 601C412.7 601 375.3 601 337.8 601C300.3 601 262.7 601 225.2 601C187.7 601 150.3 601 112.8 601C75.3 601 37.7 601 18.8 601L0 601Z'
      fill='#1494d2'
    ></path>
    <path
      d='M0 446L18.8 447.3C37.7 448.7 75.3 451.3 112.8 455.7C150.3 460 187.7 466 225.2 472.2C262.7 478.3 300.3 484.7 337.8 487.5C375.3 490.3 412.7 489.7 450.2 490C487.7 490.3 525.3 491.7 562.8 485.3C600.3 479 637.7 465 675.2 466.2C712.7 467.3 750.3 483.7 787.8 483.2C825.3 482.7 862.7 465.3 881.3 456.7L900 448L900 601L881.3 601C862.7 601 825.3 601 787.8 601C750.3 601 712.7 601 675.2 601C637.7 601 600.3 601 562.8 601C525.3 601 487.7 601 450.2 601C412.7 601 375.3 601 337.8 601C300.3 601 262.7 601 225.2 601C187.7 601 150.3 601 112.8 601C75.3 601 37.7 601 18.8 601L0 601Z'
      fill='#2084c3'
    ></path>
    <path
      d='M0 504L18.8 503.5C37.7 503 75.3 502 112.8 507.2C150.3 512.3 187.7 523.7 225.2 525.5C262.7 527.3 300.3 519.7 337.8 513.2C375.3 506.7 412.7 501.3 450.2 504.5C487.7 507.7 525.3 519.3 562.8 521.2C600.3 523 637.7 515 675.2 508.2C712.7 501.3 750.3 495.7 787.8 500.3C825.3 505 862.7 520 881.3 527.5L900 535L900 601L881.3 601C862.7 601 825.3 601 787.8 601C750.3 601 712.7 601 675.2 601C637.7 601 600.3 601 562.8 601C525.3 601 487.7 601 450.2 601C412.7 601 375.3 601 337.8 601C300.3 601 262.7 601 225.2 601C187.7 601 150.3 601 112.8 601C75.3 601 37.7 601 18.8 601L0 601Z'
      fill='#2874b4'
    ></path>
    <path
      d='M0 538L18.8 537C37.7 536 75.3 534 112.8 534C150.3 534 187.7 536 225.2 538.2C262.7 540.3 300.3 542.7 337.8 544C375.3 545.3 412.7 545.7 450.2 546.2C487.7 546.7 525.3 547.3 562.8 546C600.3 544.7 637.7 541.3 675.2 539C712.7 536.7 750.3 535.3 787.8 539.2C825.3 543 862.7 552 881.3 556.5L900 561L900 601L881.3 601C862.7 601 825.3 601 787.8 601C750.3 601 712.7 601 675.2 601C637.7 601 600.3 601 562.8 601C525.3 601 487.7 601 450.2 601C412.7 601 375.3 601 337.8 601C300.3 601 262.7 601 225.2 601C187.7 601 150.3 601 112.8 601C75.3 601 37.7 601 18.8 601L0 601Z'
      fill='#2e65a4'
    ></path>
  </svg>
)
