import LogoText from '@assets/logo_text.webp'
import { useForm } from '@tanstack/react-form'
import { Link, useLayoutEffect, useNavigate, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { motion } from 'framer-motion'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdMarkEmailRead } from 'react-icons/md'
import { toast } from 'react-toastify'

import { useAuth } from '@/auth'
import { Route } from '@/routes/register'
import { IQueryRegister, useRegisterMutation } from '@/shared/api/authQuery'
import Button from '@/shared/components/button/Button'
import { LanguageSelector } from '@/shared/components/dropdown/LanguageSelector'
import InputCheckbox from '@/shared/components/input/InputCheckbox'
import InputText from '@/shared/components/input/InputText'

import { LoginBlob } from '../login/components/LoginBlob'
import { Stepper, Steps } from './components/Stepper'
import {
  CoverBlock,
  LoginButton,
  NextIcon,
  RegisterContent,
  RegisterHeader,
  RegisterStep,
  RegisterSuccess,
  RegisterWrapper,
} from './registerElements'
import {
  companyNameValidator,
  companyTagValidator,
  companyVatIdValidator,
  confirmPasswordValidator,
  emailValidator,
  firstNameValidator,
  lastNameValidator,
  passwordValidator,
  phoneNumberValidator,
  termsValidator,
  validateConflicts,
} from './utils/validators'
import convertPhoneNumber from '@/shared/utils/convertPhoneNumber'

interface IRegisterFormReq extends IQueryRegister {
  confirmPassword?: string
  terms?: boolean
}

const Register = () => {
  const auth = useAuth()
  const { t } = useTranslation()
  const router = useRouter()
  const navigate = useNavigate({ from: '/register' })
  const [isFirstLoad, setFirstLoad] = useState<boolean>(true)
  const search = Route.useSearch()

  const { mutateAsync: register } = useRegisterMutation()

  const [step, setStep] = useState<Steps>(1)
  const [isInTransition, setIsInTransition] = useState<boolean>(false)
  const [isRegisterSuccess, setRegisterSuccess] = useState<boolean>(false)

  useLayoutEffect(() => {
    if (auth.user && search.redirect) {
      if (search.redirect) {
        router.history.push(search.redirect)
      } else {
        router.history.push('/')
      }
    }
  }, [search.redirect, router.history, auth.user])

  const handleLoginRedirect = useCallback(() => {
    if (isFirstLoad) {
      setFirstLoad(false)
    } else {
      navigate({ to: '/login' })
    }
  }, [isFirstLoad, navigate])

  const _form = useForm({
    defaultValues: {
      companyName: '',
      customerTag: '',
      companyVatId: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      countryCode: '',
      phoneNumber: '',
      confirmPassword: '',
      terms: false,
    },
    onSubmit: ({ value }) => {
      const _value: IRegisterFormReq = { ...value }
      const { country } = parsePhoneNumber(_value.phoneNumber)
      delete _value.confirmPassword
      delete _value.terms
      _value.countryCode = (country ?? 'us').toLowerCase()
      _value.phoneNumber = convertPhoneNumber(_value.phoneNumber)
      _value.email = _value.email.toLowerCase()
      register(_value)
        .then((_res) => {
          setRegisterSuccess(true)
          toast.success(t('register.toast.success'))
        })
        .catch((err) => {
          if (err.response.data.code === 6) {
            const conflict = validateConflicts(err.response.data.message)

            if (conflict === 'email') {
              setStep(2)
            } else {
              setStep(1)
            }

            toast.error(t(`register.toast.${conflict}`))
          } else {
            console.error(err)
            toast.error(t('register.toast.error'))
          }
        })
    },
    validatorAdapter: zodValidator(),
  })

  const registerForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          _form.handleSubmit()
        }}
      >
        <Stepper
          activeStep={step}
          setStep={setStep}
        />

        <RegisterStep $show={step === 1}>
          <_form.Field
            name='companyName'
            validators={{
              onChange: companyNameValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.companyName')}
                  placeholder={t('register.enterCompanyName')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                />
              </>
            )}
          </_form.Field>
          <_form.Field
            name='customerTag'
            validators={{
              onChange: companyTagValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.customerTag')}
                  placeholder={t('register.enterCustomerTag')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                />
              </>
            )}
          </_form.Field>
          <_form.Field
            name='companyVatId'
            validators={{
              onChange: companyVatIdValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.companyVatId')}
                  placeholder={t('register.enterCompanyVatId')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                />
              </>
            )}
          </_form.Field>
        </RegisterStep>

        <RegisterStep $show={step === 2}>
          <_form.Field
            name='firstName'
            validators={{
              onChange: firstNameValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.firstName')}
                  placeholder={t('register.enterFirstName')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                />
              </>
            )}
          </_form.Field>
          <_form.Field
            name='lastName'
            validators={{
              onChange: lastNameValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.lastName')}
                  placeholder={t('register.enterLastName')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                />
              </>
            )}
          </_form.Field>
          <_form.Field
            name='email'
            validators={{
              onChange: emailValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.email')}
                  placeholder={t('register.enterEmail')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                />
              </>
            )}
          </_form.Field>
        </RegisterStep>

        <RegisterStep $show={step === 3}>
          <_form.Field
            name='phoneNumber'
            validators={{
              onChange: phoneNumberValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.phoneNumber')}
                  placeholder={t('register.enterPhoneNumber')}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                  type='tel'
                />
              </>
            )}
          </_form.Field>
          <_form.Field
            name='password'
            validators={{
              onChange: passwordValidator,
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.password')}
                  placeholder={t('register.enterPassword')}
                  error={field.state.meta.errors[0] as string}
                  type='password'
                  size='md'
                />
              </>
            )}
          </_form.Field>
          <_form.Field
            name='confirmPassword'
            validators={{
              onChangeListenTo: ['password'],
              onChange: ({ fieldApi }) => {
                return confirmPasswordValidator
                  .refine(
                    () =>
                      _form.getFieldValue('confirmPassword') === _form.getFieldValue('password'),
                    {
                      message: t('register.validator.samePassword'),
                    },
                  )
                  .safeParse(fieldApi.form.fieldInfo.password.instance?.getValue()).error?.issues[0]
                  .message
              },
            }}
          >
            {(field) => (
              <>
                <InputText
                  id={field.name}
                  value={field.state.value}
                  onChange={(v: string) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  label={t('register.confirmPassword')}
                  placeholder={t('register.enterConfirmPassword')}
                  error={field.state.meta.errors[0] as string}
                  type='password'
                  size='md'
                />
              </>
            )}
          </_form.Field>

          <_form.Field
            name='terms'
            validators={{
              onChange: termsValidator,
            }}
          >
            {(field) => (
              <>
                <InputCheckbox
                  id={field.name}
                  isSelected={field.state.value}
                  onChange={(v: boolean) => field.handleChange(v)}
                  onBlur={field.handleBlur}
                  error={field.state.meta.errors[0] as string}
                  size='md'
                  rtl
                >
                  {t('register.terms')}
                </InputCheckbox>
              </>
            )}
          </_form.Field>
        </RegisterStep>

        {step !== 3 && (
          <Button
            label={t('register.nextStep')}
            onClick={() => setStep((prev) => (prev + 1) as 1 | 2 | 3)}
            btnType='secondary'
            fullWidth
            iconEnd={<NextIcon />}
          />
        )}
        {step === 3 && (
          <_form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
            {([canSubmit, isSubmitting]) => (
              <Button
                type='submit'
                btnType='secondary'
                isDisabled={!canSubmit}
                label={isSubmitting ? '...' : t('register.btnRegister')}
                onClick={() => {
                  return null
                }}
                size='md'
                fullWidth
              />
            )}
          </_form.Subscribe>
        )}
        <span>
          <Link to={'/login'}>{t('register.goToSignIn')}</Link>
        </span>
      </form>
    )
  }

  return (
    <RegisterWrapper>
      <RegisterHeader
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <span>
          <img
            src={LogoText}
            alt='logo'
          />
        </span>
        <div className='lang-select'>
          <LanguageSelector />
        </div>
      </RegisterHeader>
      <RegisterContent
        initial={{ opacity: 0 }}
        animate={isInTransition ? { opacity: 0 } : { opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h1>{t('register.registerTitle')}</h1>
        {!isRegisterSuccess && registerForm()}
        {isRegisterSuccess && (
          <RegisterSuccess>
            <motion.div
              animate={{
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: 'loop',
                ease: 'easeInOut',
              }}
            >
              <MdMarkEmailRead />
            </motion.div>

            <p>{t('register.successMsg')}</p>
          </RegisterSuccess>
        )}
        <LoginBlob />
        <LoginButton
          whileHover={{
            x: '1.9dvw',
            transition: { duration: 1 },
          }}
          initial={{ x: '-8vw' }}
          animate={isInTransition ? { x: '50vw', opacity: 0 } : { x: '0vw', opacity: 1 }}
          transition={{ duration: 0.5 }}
          onClick={() => setIsInTransition(true)}
        >
          {t('register.goToSignIn')}
        </LoginButton>
      </RegisterContent>
      <CoverBlock
        animate={isInTransition ? { width: '50dvw' } : { width: '0' }}
        transition={{ duration: 0.5 }}
        onAnimationComplete={() => handleLoginRedirect()}
      />
    </RegisterWrapper>
  )
}

export default Register
