import { useEffect, useState } from 'react'

import { commonTheme } from '../theme/commonTheme'

const useDimBreakpoints = () => {
  const [matches, setMatches] = useState({
    xs: false,
    s: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    xxxl: false,
  })

  useEffect(() => {
    const breakpoints = commonTheme.breakpoint
    const mediaQueries = {
      xs: window.matchMedia(`(max-width: ${breakpoints.xs})`),
      s: window.matchMedia(`(max-width: ${breakpoints.s})`),
      md: window.matchMedia(`(max-width: ${breakpoints.md})`),
      lg: window.matchMedia(`(max-width: ${breakpoints.lg})`),
      xl: window.matchMedia(`(max-width: ${breakpoints.xl})`),
      xxl: window.matchMedia(`(max-width: ${breakpoints.xxl})`),
      xxxl: window.matchMedia(`(max-width: ${breakpoints.xxxl})`),
    }

    const handleMediaQueryChange = () => {
      setMatches({
        xs: mediaQueries.xs.matches,
        s: mediaQueries.s.matches,
        md: mediaQueries.md.matches,
        lg: mediaQueries.lg.matches,
        xl: mediaQueries.xl.matches,
        xxl: mediaQueries.xxl.matches,
        xxxl: mediaQueries.xxxl.matches,
      })
    }

    handleMediaQueryChange()

    for (const mq of Object.values(mediaQueries)) {
      if (mq.addEventListener) {
        mq.addEventListener('change', handleMediaQueryChange)
      } else {
        mq.addListener(handleMediaQueryChange)
      }
    }

    return () => {
      for (const mq of Object.values(mediaQueries)) {
        if (mq.removeEventListener) {
          mq.removeEventListener('change', handleMediaQueryChange)
        } else {
          mq.removeListener(handleMediaQueryChange)
        }
      }
    }
  }, [])

  return matches
}

export default useDimBreakpoints
