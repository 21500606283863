import { motion } from 'framer-motion'

export const LoginBlob = () => (
  <svg
    width='760'
    height='675'
    viewBox='0 -60 760 760'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid slice'
  >
    <g filter='url(#filter0_d_2_18)'>
      <motion.path
        d='M727.133 121.085C771.85 204.659 719.308 335.755 647.76 445.548C576.213 555.887 486.778 644.377 372.191 658.033C257.603 671.688 118.98 611.056 51.9038 503.448C-15.172 396.387 -10.1413 242.349 59.7293 145.119C129.041 47.8898 262.634 7.4685 401.257 4.73734C539.321 2.5524 682.416 38.0576 727.133 121.085Z'
        fill='url(#paint0_linear_2_18)'
        preserveAspectRatio='xMidYMid slice'
        animate={{
          rotate: [0, 2, -8, 0],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'easeInOut',
        }}
      />
    </g>
    <defs>
      <filter
        id='filter0_d_2_18'
        x='0.748632'
        y='-10'
        width='758.518'
        height='974.494'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood
          floodOpacity='0'
          result='BackgroundImageFix'
        />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset
          dx='6'
          dy='5'
        />
        <feGaussianBlur stdDeviation='4.8' />

        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_2_18'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_2_18'
          result='shape'
        />
      </filter>
      <motion.linearGradient
        id='paint0_linear_2_18'
        x1='79.5'
        y1='34.5'
        x2='374.008'
        y2='659.937'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='#25E0FF'
          stopOpacity='0.79'
        />
        <stop
          offset='0.885'
          stopColor='#2B67AB'
          stopOpacity='0.95'
        />
      </motion.linearGradient>
    </defs>
  </svg>
)
