import { Link } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const MobileNavbarWrapper = styled(motion.nav)<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${(props) => (props.$isOpen ? '300px' : '0')};
  z-index: 100;
  filter: drop-shadow(4px 4px 16px rgba(66, 68, 90, 1));
  -webkit-backface-visibility: hidden;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: ${(props) => props.theme.colors.gradient3};
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
  }

  button {
    outline: none;
    border: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    bottom: 11px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    padding: 25px;
    position: absolute;
    top: 100px;
    width: 230px;
  }

  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    a,
    div {
      font-size: ${(props) => props.theme.fontSize.lmd};
      color: ${(props) => props.theme.colors.whiteText};
    }

    svg {
      font-size: ${(props) => `calc(${props.theme.fontSize.lmd} + 0.2rem)`};
      margin-right: 0.5rem;
      color: ${(props) => props.theme.colors.whiteText};
    }
  }

  .navbar-logo {
    position: fixed;
    z-index: 101;
    width: 300px;
    display: flex;
    top: 20px;
    left: 20px;
    align-items: center;

    img {
      width: auto;
      height: 50px;
    }

    h1 {
      width: 65%;
      font-size: ${(props) => props.theme.fontSize.lmd};
      color: ${(props) => props.theme.colors.whiteText};
      font-weight: 300;
      margin-bottom: 2px;
      margin-top: auto;
      margin-left: 16px;
    }
  }
`

export const NavbarPath = styled(motion.path)`
  fill: transparent;
  stroke-width: 3;
  stroke: ${(props) => props.theme.colors.whiteText};
  stroke-linecap: round;
`

export const NavbarMobileLogoStyled = styled(Link)`
  display: flex;
  text-decoration: none;
  top: 20px;
  left: 20px;
  position: relative;
  margin-bottom: 24px;
  width: calc(100% - 20px);

  img {
    width: auto;
    height: 50px;
  }

  h1 {
    background: ${(props) => props.theme.colors.gradient2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300;
    margin-bottom: 2px;
    margin-top: auto;
    margin-left: 16px;
  }
`
