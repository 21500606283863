import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const LicenseCardWrapper = styled(motion.div)`
  display: flex;
  width: 1000px;
  height: 300px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  box-shadow: ${(props) => props.theme.shadows.md};
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
    height: 800px;
    width: 500px;
    flex-direction: column;
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
    height: 600px;
    width: 300px;
    flex-direction: column;
  }
`

export const LicenseCardImage = styled.div`
  width: 300px;
  height: 300px;
  background-color: ${(props) => props.theme.colors.primary400};

  img {
    height: 300px;
    width: 300px;
  }

  @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
    width: 500px;
    height: 500px;

    img {
      width: 500px;
      height: 500px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
    width: 300px;
    height: 300px;

    img {
      width: 300px;
      height: 300px;
    }
  }
`

export const LicenseCardContent = styled.div`
  display: flex;
  width: calc(700px - 32px);
  height: calc(300px - 32px);
  flex-wrap: wrap;
  padding: 16px;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  background: ${(props) => props.theme.colors.bgGlass};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  h1 {
    margin-top: 0;
    margin-bottom: 0.25rem;
    font-size: 2rem;
    background: ${(props) => props.theme.colors.gradient2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    text-overflow: ellipsis;
  }

  span.product-card-text {
    display: flex;
    flex-direction: column;
    max-height: calc(300px - 32px - 50px - 1rem);

    > p {
      max-height: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      max-width: 80ch;

      > span {
        font-weight: 600;
      }
    }
  }

  span.product-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > p {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.lg}) {
    width: calc(500px - 32px);
    height: calc(300px - 32px);

    span.product-card-footer {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
    width: calc(300px - 32px);
    height: calc(300px - 32px);

    span.product-card-footer {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`
