import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: center;
`

export const SkeletonBar = styled.div<{
  $height: number
  $fullWidth: boolean
  $width: number
}>`
  width: ${(props) => (props.$fullWidth ? `100%` : `${props.$width}px`)};
  background-color: ${(props) => props.theme.skeleton.background};
  border-radius: ${(props) => props.theme.borderRadius.md};
  overflow: hidden;
  height: ${(props) => `${props.$height}px`};
`

const _Shimmer = styled(motion.div)`
  background: ${(props) => props.theme.skeleton.shimmer};
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
`
interface IShimmer {
  fullWidth: boolean
  width: number
}

export const Shimmer = ({ fullWidth, width }: IShimmer) => {
  return (
    <_Shimmer
      initial={{ x: '-100%' }}
      animate={{ x: fullWidth ? '250%' : `${width}px` }}
      transition={{
        duration: 1.2,
        repeat: Infinity,
        repeatDelay: 0.5,
      }}
    />
  )
}
