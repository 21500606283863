import { AsYouType } from 'libphonenumber-js'
import { ChangeEvent, useRef } from 'react'
import type { AriaTextFieldProps } from 'react-aria'
import { useTextField } from 'react-aria'

import { Size } from '@/shared/model/common'

import { Input, InputError, InputLabel, InputLabelFloat, InputWrapper } from './InputText.styled'

type InputType = 'text' | 'password' | 'email' | 'number' | 'tel'

interface InputTextProps extends AriaTextFieldProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void
  error?: string
  size?: Size
  type?: InputType
  fullWidth?: boolean
  floatLabel?: boolean
}

const InputText = (props: InputTextProps) => {
  const ref = useRef(null)
  const { labelProps, inputProps } = useTextField(props, ref)

  const {
    id,
    label,
    type,
    onChange,
    size = 'md',
    fullWidth = false,
    isRequired = false,
    floatLabel = false,
    isDisabled = false,
    placeholder,
    error,
  } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (type === 'tel') {
      const formattedPhoneNumber = new AsYouType().input(event.target.value)
      onChange?.(formattedPhoneNumber)
    } else {
      onChange?.(event.target.value)
    }
  }

  return (
    <InputWrapper
      $fullWidth={fullWidth}
      $floatLabel={floatLabel}
      $size={size}
    >
      {label && !floatLabel && (
        <InputLabel
          htmlFor={id}
          $size={size}
          {...labelProps}
        >
          {label}
          {isRequired && '*'}
        </InputLabel>
      )}
      <Input
        ref={ref}
        aria-labelledby={id}
        {...inputProps}
        placeholder={floatLabel ? '' : placeholder}
        onChange={handleChange}
        $disabled={isDisabled}
        $floatLabel={floatLabel}
        $size={size}
      />
      {label && floatLabel && (
        <InputLabelFloat
          htmlFor={id}
          $size={size}
        >
          {label}
          {isRequired && '*'}
        </InputLabelFloat>
      )}
      <InputError>{error}</InputError>
    </InputWrapper>
  )
}

export default InputText
