import { LogoLoaderWrapper, LogoSVGMotion } from './LoaderLogoElements'

export interface ILogoSVGMotion {
  duration?: number
  stroke?: string
  size?: number
  dim?: 'height' | 'width'
}

const LoaderLogo = ({
  duration = 2,
  stroke = 'white',
  size = 425,
  dim = 'width',
}: ILogoSVGMotion) => {
  return (
    <LogoLoaderWrapper>
      <LogoSVGMotion
        duration={duration}
        stroke={stroke}
        size={size}
        dim={dim}
      />
    </LogoLoaderWrapper>
  )
}

export default LoaderLogo
