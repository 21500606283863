export const commonTheme = {
  colors: {
    primary100: '#00A5E0',
    primary200: '#0595D0',
    primary300: '#0988C3',
    primary400: '#2E65A4',
    whiteText: '#f1f5f9',
    gradient: 'linear-gradient(90deg, rgba(0, 165, 224, 1) 0%, rgba(46, 101, 164, 1) 100%)',
    gradient2: 'linear-gradient(135deg, #2E65A4 0%, #0988C3 15%)',
    gradient3: 'linear-gradient(35deg, #2E65A4 0%, #0988C3 150%)',
    bgGlass: 'rgba(209, 209, 209, 0.301)',
  },
  fontSize: {
    xsm: '0.5rem',
    sm: '0.75rem',
    smd: '0.8rem',
    md: '1rem',
    lmd: '1.2rem',
    lg: '2rem',
    xlg: '3rem',
  },
  breakpoint: {
    xs: '575.98px',
    s: '767.98px',
    md: '991.98px',
    lg: '1199.98px',
    xl: '1399.98px',
    xxl: '1499.98px',
    xxxl: '1599.98px',
  },
  borderRadius: {
    xsm: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
  },
  shadows: {
    sm: '4px 4px 16px -8px rgba(66, 68, 90, 1)',
    md: '4px 4px 16px -6px rgba(66, 68, 90, 1)',
    lg: '4px 4px 16px -4px rgba(66, 68, 90, 1)',
    xl: '4px 4px 16px -2px rgba(66, 68, 90, 1)',
  },
  fonts: ['sans-serif', 'Roboto'],
  skeleton: {
    background: 'rgba(230, 230, 230, 1)',
    shimmer:
      'linear-gradient(90deg, rgba(230, 230, 230, 1) 0%, rgba(245, 245, 245, 1) 50%, rgba(230, 230, 230, 1) 100%)',
  },
}
