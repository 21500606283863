/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { motion, MotionProps } from 'framer-motion'
import { darken } from 'polished'
import { css, styled } from 'styled-components'

import { IButton } from './Button'

// This interface just fixes type errors between styled-components and react-aria
// The main interface is in Button.tsx, so any type won't change anything
interface IStyledButton
  extends MotionProps,
    Omit<IButton, 'label' | 'onHoverEnd' | 'onHoverStart' | 'style'> {
  onFocus?: any
  children?: any
  ref?: any
  onBlur?: any
  onClick?: any
  value?: any
  disabled?: boolean
}

const disabledBtn = css`
  background-color: ${(props) => props.theme.complementaryColors.disabled};
  color: ${(props) => props.theme.complementaryColors.disabledText};
  cursor: not-allowed;
`

const enabledBtn = css<IStyledButton>`
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      darken(
        0.1,
        props.btnType === 'primary' ? props.theme.colors.primary : props.theme.colors.secondary,
      )};
  }

  &:active {
    scale: 0.9;
  }
`

const animatedGradient = css`
  background: ${(props) =>
    `linear-gradient(270deg, ${props.theme.colors.primary100}, ${props.theme.colors.primary200}, ${props.theme.colors.primary300}, ${props.theme.colors.primary400})`};
  background-size: 800% 800%;
`

export const ButtonStyled = styled(motion.button)<IStyledButton>`
  display: flex;
  outline: none;
  padding: 20px 35px;
  border: 0;
  background-color: ${(props) =>
    props.btnType === 'primary' ? props.theme.colors.primary : props.theme.colors.secondary};
  border-radius: ${(props) => `calc(${props.theme.borderRadius[props.size!]} * ${props.pill})`};
  color: ${(props) => props.theme.colors.textSecondary};
  transition: all 0.2s;
  user-select: none;
  box-shadow: ${(props) => props.theme.shadows[props.size!]};
  justify-content: center;
  column-gap: 0.5rem;
  width: ${(props) => (props.fullWidth ? '100%' : 'unset')};
  font-size: ${(props: any) => props.theme.fontSize.md};
  height: 50px;
  ${(props) =>
    props.size === 'sm' &&
    `height: 34px; padding: 10px 16px; width: 204px; font-size: ${props.theme.fontSize.smd};`}

  ${(props) =>
    props.size === 'lg' && `height: 60px; width: 300px; font-size: ${props.theme.fontSize.lmd};`}

  ${(props) => (props.isDisabled ? disabledBtn : enabledBtn)}
  ${(props) => props.animations?.includes('gradient') && !props.isDisabled && animatedGradient}
  align-items: center;
`
