import { BsXLg } from 'react-icons/bs'

import { DropdownSelectedItemWrapper } from './dropdownElements'

interface DropdownSelectedItemProps {
  label: string
  onClick: () => void
}

const DropdownSelectedItem = ({ label, onClick }: DropdownSelectedItemProps) => {
  return (
    <DropdownSelectedItemWrapper
      onClick={onClick}
      layout='position'
    >
      <span>{label}</span>
      <BsXLg />
    </DropdownSelectedItemWrapper>
  )
}

export default DropdownSelectedItem
