import { styled } from 'styled-components'

export const NotFoundWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 80dvh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 5rem;
    color: ${(props) => props.theme.complementaryColors.error ?? '#ff2c2c'};
  }

  h1 {
    color: ${(props) => props.theme.complementaryColors.error ?? '#ff2c2c'};
  }
`
