import { motion } from 'framer-motion'
import { styled } from 'styled-components'

export const _PageWrapper = styled(motion.div)`
  display: flex;
  min-height: 75dvh;
  width: 90dvw;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  row-gap: 25px;

  h1 {
    font-size: 2.8rem;
  }

  h1.page-wrapper-gradient {
    background: ${(props) => props.theme.colors.gradient2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
