import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { api } from './api'
import { BASE_URL } from '@/config'

export interface IQueryLogin {
  email: string
  password: string
}

export interface IQueryLoginRes {
  sessionToken: string
}

export const useLoginMutation = () => {
  return useMutation({
    mutationKey: ['login'],
    mutationFn: async ({ email, password }: IQueryLogin) => {
      const { data } = await api.post(`/portal/login`, {
        email,
        password,
      })

      return data
    },
  })
}

export interface IQueryRegister {
  companyName: string
  customerTag: string
  companyVatId: string
  password: string
  firstName: string
  lastName: string
  email: string
  countryCode: string
  phoneNumber: string
}

export const useRegisterMutation = () => {
  return useMutation({
    mutationKey: ['register'],
    mutationFn: async (dataIn: IQueryRegister) => {
      const { data } = await axios.post(`${BASE_URL}/portal/register`, {
        ...dataIn,
      })

      return data
    },
  })
}
