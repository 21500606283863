export const themeLight = {
  colors: {
    text: '#0c0e11',
    textSecondary: '#f1f5f9',
    background: '#ffffff',
    backgroundFields: '#ffffff',
    primary: '#1c4e87',
    secondary: '#59B6FB',
    accent: '#00A5E0',
    whiteText: '#f1f5f9',
  },
  complementaryColors: {
    success: '#00b158', // toast success
    error: '#ff2c2c', // toast error
    warning: '#fff23d', // toast warning
    info: '#1c4e87', // toast info
    enabled: '#00e759', // switch enable background
    disabled: '#eeeded', // disabled eg. button
    disabledText: '#4f4f4f',
    placeholder: '#797775',
    toastBackground: '#ffffff',
    lightGrey: '#d6d6d6',
  },
}
