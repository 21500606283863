import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'

import { useDimensions } from '@/shared/utils/useDimensions'

import { MobileNavbarWrapper } from './NavbarMobileElements'
import { NavbarNavigation } from './NavbarNavigation'
import { NavbarToggle } from './NavbarToggle'

const sidebar = {
  open: () => ({
    clipPath: `circle(2000px at left 40px bottom 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(30px at left 40px bottom 40px)',
    transition: {
      delay: 0.2,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

export const NavbarMobile = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)

  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = 'fixed'
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [isOpen])

  return (
    <MobileNavbarWrapper
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={height}
      ref={containerRef}
      $isOpen={isOpen}
    >
      <motion.div
        className='background'
        variants={sidebar}
      />
      <NavbarNavigation toggle={setOpen} />
      <NavbarToggle toggle={() => setOpen((prev) => !prev)} />
    </MobileNavbarWrapper>
  )
}
