import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import Login from '@/views/login'

const fallback = '/' as const

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().optional(),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect ?? fallback })
    }
  },
  component: Login,
})
