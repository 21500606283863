import { redirect } from '@tanstack/react-router'
import axios from 'axios'

import { getStoredUser, setStoredUser } from '@/auth'
import { BASE_URL } from '@/config'

export const api = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 10000,
})

api.interceptors.request.use(
  (config) => {
    if (!config.url?.includes('/register') && !config.url?.includes('/login')) {
      const user = getStoredUser()
      if (!user) {
        throw redirect({ to: '/login' })
      } else {
        config.headers.Authorization = `Bearer ${user.sessionToken}`
      }
    } else {
      delete config.headers.Authorization
    }
    return config
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (401 === error.response.status) {
      setStoredUser(null)
    }
    console.error(error)
    return Promise.reject(error)
  },
)
