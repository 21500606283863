export const register = {
  registerTitle: 'Rejestracja',
  nextStep: 'Dalej',
  goToSignIn: 'Zaloguj się',
  companyName: 'Nazwa firmy',
  enterCompanyName: 'Wprowadź nazwę firmy...',
  customerTag: 'Tag firmy',
  enterCustomerTag: 'Wprowadź tag firmy...',
  companyVatId: 'Numer NIP',
  enterCompanyVatId: 'Wprowadź numer NIP...',
  firstName: 'Imię',
  enterFirstName: 'Wprowadź swoje imię...',
  lastName: 'Nazwisko',
  enterLastName: 'Wprowadź swoje nazwisko...',
  email: 'E-mail',
  enterEmail: 'Wprowadź e-mail...',
  phoneNumber: 'Numer telefonu',
  enterPhoneNumber: '+48 573 943 997',
  password: 'Hasło',
  enterPassword: 'Wprowadź hasło...',
  confirmPassword: 'Potwierdź hasło',
  enterConfirmPassword: 'Wprowadź hasło ponownie...',
  terms: 'Akceptuję warunki',
  successMsg:
    'Na podany adres e-mail wysłaliśmy link z potwierdzeniem. Należy w niego wejść w celu ukończenia rejestracji.',
  btnRegister: 'Zarejestruj się',
  toast: {
    success: 'Pomyślnie wysłano dane do rejestracji',
    error: 'Wystąpił błąd podczas rejestracji',
    email: 'Ten e-mail jest już zajęty',
    tag: 'Ten tag firmy jest już zajęty',
  },
  validator: {
    minCompanyTag: 'Wymagane są minmumu 3 znaki',
    maxVatId: 'Maksymalna długość to 10 znaków',
    minPasswd: 'Wymagane jest minimum 8 znaków',
    samePassword: 'Hasła muszą być takie same',
    passwordsDoNotMatch: 'Hasła muszą być takie same',
    requiredTerms: 'Wymagana jest akceptacja warunków',
  },
}
