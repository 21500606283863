import { useState, useCallback } from 'react'

const useAsyncState = <T>(
  initialValue: T,
): [T, (newState: T | ((prevState: T) => T)) => Promise<T>] => {
  const [state, setState] = useState<T>(initialValue)

  const asyncSetState = useCallback(
    (newState: T | ((prevState: T) => T)): Promise<T> => {
      return new Promise((resolve) => {
        setState((prevState) => {
          const value =
            typeof newState === 'function' ? (newState as (prevState: T) => T)(prevState) : newState
          resolve(value)
          return value
        })
      })
    },
    [setState],
  )

  return [state, asyncSetState]
}

export default useAsyncState
