import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { DATE_FORMAT } from '@/config'
import Button from '@/shared/components/button/Button'
import { ILicense } from '@/shared/model/customers'

import sokarLogo from '../../../assets/sokar_logo.png'
import { LicenseCardContent, LicenseCardImage, LicenseCardWrapper } from './licenseCardElements'

export interface ILicenseCard extends ILicense {
  index: number
}

export const LicenseCard = ({
  appName,
  appUrl,
  companyName,
  companyVatId,
  expiresAt,
  createdAt,
  lastPaymentAt,
  index,
}: ILicenseCard) => {
  const { t } = useTranslation()

  return (
    <LicenseCardWrapper
      initial={{
        opacity: 0,
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.6,
        },
      }}
      viewport={{ once: true }}
    >
      <LicenseCardImage>
        <img
          src={sokarLogo}
          alt='sokar logo'
        />
      </LicenseCardImage>
      <LicenseCardContent>
        <span className='product-card-text'>
          <h1>{appName}</h1>
          <p>
            <span>{companyName}</span>
          </p>
          <p>
            <span>{t('products.vatId')}:</span> {companyVatId}
          </p>
          <p>
            <span>{t('products.createdAt')}:</span> {dayjs(createdAt, '', 'pl').format(DATE_FORMAT)}
          </p>
          <p>
            <span>{t('products.lastPaymentAt')}:</span> {dayjs(lastPaymentAt).format(DATE_FORMAT)}
          </p>
          <p>
            <span>{t('products.expiresAt')}:</span> {dayjs(expiresAt).format(DATE_FORMAT)}
          </p>
        </span>
        <span className='product-card-footer'>
          <p></p>
          <a
            href={`https://${appUrl}`}
            target='_blank'
            rel='noreferrer'
          >
            <Button
              label={t('products.goTo')}
              size='md'
              isDisabled={dayjs(expiresAt) <= dayjs()}
              animations={['tap', 'hover', 'gradient']}
            />
          </a>
        </span>
      </LicenseCardContent>
    </LicenseCardWrapper>
  )
}
