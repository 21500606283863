/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import {
  init,
  replayIntegration,
  browserProfilingIntegration,
  tanstackRouterBrowserTracingIntegration,
} from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'

import { AuthProvider, useAuth } from './auth'
import ErrorBoundary from './ErrorBoundry'
import Translations from './i18n/Translations'
import { PendingWrapper } from './mainElements'
import { routeTree } from './routeTree.gen'
import LoaderLogo from './shared/components/loaderLogo/LoaderLogo'
import { theme } from './shared/theme/theme'
import useTheme from './shared/utils/useTheme'
import ErrorView from './views/errorView'
import NotFoundView from './views/notFound'

const queryClient = new QueryClient()

// Set up a Router instance
export const router = createRouter({
  routeTree,
  defaultNotFoundComponent: NotFoundView,
  defaultPendingComponent: () => (
    <PendingWrapper>
      <LoaderLogo duration={1.5} />
    </PendingWrapper>
  ),
  defaultErrorComponent: ErrorView,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultPendingMinMs: 500,
  context: {
    auth: undefined!,
    queryClient,
  },
})

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://ce907e3dfd764bd836333a3fd391ede7@sentry.onitsoft.com/5',
    integrations: [
      tanstackRouterBrowserTracingIntegration(router),
      browserProfilingIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  })
}

const InnerApp = () => {
  const auth = useAuth()

  return (
    <RouterProvider
      router={router}
      notFoundMode='fuzzy'
      defaultPreload='intent'
      defaultPendingMinMs={500}
      context={{
        auth,
      }}
    />
  )
}

const App = () => {
  const { theme: currentTheme } = useTheme()

  return (
    <ErrorBoundary>
      <AuthProvider>
        <ThemeProvider theme={theme[currentTheme]}>
          <InnerApp />
          <Normalize />
        </ThemeProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
}

const rootElement = document.getElementById('root')!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <Translations>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Translations>,
  )
}
