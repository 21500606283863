import { queryOptions, useQuery } from '@tanstack/react-query'

import { ICustomer, ILicenseRes } from '../model/customers'
import { api } from './api'

export const useGetCustomerId = (customerTag?: string) => {
  return useQuery({
    queryKey: ['customerId', customerTag],
    enabled: !!customerTag,
    queryFn: async () => {
      const { data } = await api.get<{ customerId: string }>(`/portal/customer-id`, {
        params: { customerTag },
      })
      return data
    },
  })
}

export const useGetLicensesQuery = (active?: boolean) => {
  return useQuery({
    queryKey: ['licenses', 'own-license', active],
    queryFn: async () => {
      const { data } = await api.get<ILicenseRes>(`/portal/licenses`, {
        params: { active },
      })
      return data.licenses
    },
  })
}

export const useGetLicensesByIdQuery = (id: string) => {
  return queryOptions({
    queryKey: ['licenses', id],
    queryFn: async () => {
      const { data } = await api.get<ILicenseRes>(`/portal/customers/${id}/licenses`)
      return data
    },
  })
}

export interface IProfileRes {
  customer: ICustomer
}

export const useGetUserProfile = () => {
  return useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const { data } = await api.get<IProfileRes>(`/portal/profile`)
      return data.customer
    },
  })
}
