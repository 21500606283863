import { useState } from 'react'

import { useLang } from '@/shared/utils/useLang'

import Dropdown, { Option } from './Dropdown'

export const LanguageSelector = () => {
  const { getLangOption, setLangOption } = useLang()

  const createOptions = () => {
    return [
      { label: 'Polski', value: 'pl' },
      { label: 'English', value: 'en' },
    ]
  }

  const [options, setOptions] = useState<Option[]>(createOptions())

  const handleLangChange = (option: Option[]) => {
    setLangOption(option).then(() => setOptions(createOptions()))
  }

  return (
    <Dropdown
      id='langSelector'
      name='langSelector'
      value={getLangOption()}
      onChange={handleLangChange}
      options={options}
      size='lg'
    />
  )
}
