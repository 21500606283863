import { changeLanguage, t } from 'i18next'

import { Option } from '../components/dropdown/Dropdown'
import { Languages } from '../model/common'

export const useLang = () => {
  const getLang = () => {
    return localStorage.getItem('i18nextLng')
  }

  const getLangOption = () => {
    const lang = localStorage.getItem('i18nextLng')
    return [{ label: t(`common.lang.${lang}`), value: lang }] as Option[]
  }

  const setLang = (lang: Languages) => {
    return changeLanguage(lang)
  }

  const setLangOption = (option: Option[]) => {
    return changeLanguage(option[0].value)
  }

  return { getLang, getLangOption, setLang, setLangOption }
}
