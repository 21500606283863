/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from 'styled-components'

import { Size } from '@/shared/model/common'

export const InputErrorDefault = styled.p`
  margin-top: 6px;
  margin-left: 2px;
  height: ${(props: any) => props.theme.fontSize.smd};
  font-size: ${(props: any) => props.theme.fontSize.smd};
  color: ${(props: any) => props.theme.complementaryColors.error};
`

export const InputLabelDefault = styled.label<{ $size: Size }>`
  margin-bottom: 4px;
  margin-left: 2px;
  color: ${(props: any) => props.theme.colors.text};
  ${(props) => props.$size === 'sm' && `font-size: ${props.theme.fontSize.smd};`}
  ${(props) => props.$size === 'lg' && `font-size: ${props.theme.fontSize.lmd};`}
`

export const InputWrapperDefault = styled.div<{
  $size: Size
  $fullWidth: boolean
}>`
  display: flex;
  flex-direction: column;
  margin: 12px;
  max-width: ${(props: any) => (props.fullWidth ? 'unset' : '300px')};
  position: ${(props: any) => (props.floatLabel ? 'relative' : 'unset')};
  min-height: ${(props) =>
    `calc(${props.theme.fontSize.smd} + 6px + ${props.theme.fontSize.smd} + 55px)`};
  ${(props) =>
    props.$size === 'sm' &&
    `min-height: calc(${props.theme.fontSize.smd} + 6px + ${props.theme.fontSize.smd});`}
  ${(props) => props.$size === 'lg' && `min-height: ${props.theme.fontSize.lmd};`}
`

export const InputLabelFloatDefault = styled.label<{ $size: Size }>`
  color: ${(props: any) => props.theme.complementaryColors.placeholder};
  position: absolute;
  left: 10px;
  transform: translateY(calc(-50%));
  letter-spacing: 1px;
  transition: 0.15s;
  background: transparent;
  top: ${(props: any) => `calc(${props.theme.fontSize.md} + 8px)`};

  ${(props) =>
    props.$size === 'sm' &&
    `font-size: ${props.theme.fontSize.smd}; top: calc(${props.theme.fontSize.smd} + 6px);`};
  ${(props) =>
    props.$size === 'lg' &&
    `font-size: ${props.theme.fontSize.lmd}; top: calc(${props.theme.fontSize.lmd} + 6px);`};
`
