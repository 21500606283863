import { styled } from 'styled-components'

export const _LoadingWrapper = styled.div`
  display: flex;
  width: 90dvw;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;

  .loader.loader-space {
    height: 25px;
  }
`
