import { Link } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa6'

import logoText from '@assets/logo_text.webp'
import { FooterBlock, FooterCopyright, FooterWrapper } from './FooterElements'
import { Wave } from './Wave'

const Footer = () => {
  const { t } = useTranslation()
  const version = import.meta.env.VITE_APP_VERSION

  return (
    <FooterWrapper>
      <Wave />
      <FooterBlock>
        <div className='col1'>
          <img
            src={logoText}
            alt='logoFooter'
          />
          <p>Onit Soft sp. z o.o.</p>
          <p>
            ul. Cystersów 13A/1
            <br />
            31-553, Kraków
          </p>
          <p>
            VAT-ID (NIP): 6751787127
            <br />
            REGON: 526210032
            <br />
            KRS: 0001054906
          </p>
        </div>

        <div className='col2'>
          <h2>{t('footer.followUs')}</h2>
          <a
            href='https://www.facebook.com/onitsoft'
            target='_blank'
            rel='noreferrer noopener'
          >
            <FaFacebookSquare />
          </a>
          <a
            href='https://www.linkedin.com/company/onit-soft/'
            target='_blank'
            rel='noreferrer noopener'
          >
            <FaLinkedin />
          </a>
        </div>

        <div className='col3'>
          <a href='tel:+48573943997'>{t('footer.phoneNumber')}</a>
          <a href='mailto:info@onitsoft.com'>{t('footer.email')}</a>
          <Link>{t('footer.terms')}</Link>
          <Link>{t('footer.sitemap')}</Link>
        </div>
      </FooterBlock>
      {version && <FooterCopyright>ver. {version}</FooterCopyright>}
      <FooterCopyright>© 2024 Onitsoft sp. z o.o. All right reserved.</FooterCopyright>
    </FooterWrapper>
  )
}

export default Footer
