import { useState } from 'react'

import { Theme } from '../model/common'
import getTheme from './getTheme'
import setTheme from './setTheme'

const useTheme = () => {
  const lsTheme = getTheme()
  const [theme, setCurrentTheme] = useState<Theme>(lsTheme)

  const updateTheme = (_theme: Theme) => {
    setCurrentTheme(_theme)
    setTheme(_theme)
  }

  return { theme, updateTheme }
}

export default useTheme
