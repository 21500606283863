import { Outlet } from '@tanstack/react-router'
import { useCallback, useEffect, useState } from 'react'

import Footer from './Footer'
import { NavbarMobile } from './MobileNavbar/NavbarMobile'
import NavbarMobileLogo from './MobileNavbar/NavbarMobileLogo'
import Navbar from './Navbar'
import { useGetUserProfile } from '@/shared/api/customersQuery'
import { useAuth } from '@/auth'

const Layout = () => {
  const { updateUser } = useAuth()
  const { data: customer, isError, isLoading } = useGetUserProfile()
  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [handleWindowSizeChange])

  const isMobile = width <= 1024

  useEffect(() => {
    if (!isLoading && !isError && customer) {
      updateUser(customer)
    }
  }, [customer])

  return (
    <>
      {isMobile && <NavbarMobileLogo />}
      {!isMobile ?
        <Navbar />
      : <NavbarMobile />}
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout
