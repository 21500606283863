import {
  arrow,
  flip,
  FloatingPortal,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { useRef, useState } from 'react'

import { TooltipArrow, TooltipContent, TooltipWrapper } from './tooltipElements'

export interface ITooltip {
  children: JSX.Element[] | JSX.Element
  content: string
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'right-start'
    | 'right-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'left-start'
    | 'left-end'
}

const Tooltip = ({ children, content, placement }: ITooltip) => {
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      arrow({
        element: arrowRef,
      }),
      flip(),
      shift(),
    ],
    placement,
  })

  const hover = useHover(context, {
    delay: {
      open: 200,
      close: 100,
    },
  })

  const role = useRole(context, {
    role: 'tooltip',
  })

  const focus = useFocus(context)

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, role, focus, dismiss])

  return (
    <>
      <TooltipWrapper
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </TooltipWrapper>
      {isOpen && (
        <FloatingPortal>
          <TooltipContent
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {content}
            <TooltipArrow
              ref={arrowRef}
              context={context}
            />
          </TooltipContent>
        </FloatingPortal>
      )}
    </>
  )
}

export default Tooltip
