import { Link, useLocation } from '@tanstack/react-router'
import { AnimatePresence, Variants } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@/auth'
import useTheme from '@/shared/utils/useTheme'

import Switch from '../../switch/Switch'
import Tooltip from '../../tooltip/Tooltip'
import { MenuItem, MenuList, MenuWrapper } from './MenuElements'
import { useEffect } from 'react'

export interface IMenu {
  isOpen: boolean
  theme: 'light' | 'dark'
  setMenuOpen: (isOpen: boolean) => void
}

const menuVariants: Variants = {
  open: {
    opacity: 1,
    height: 180,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 24,
      staggerChildren: 0.1,
    },
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.4,
      staggerChildren: 0.02,
      staggerDirection: -1,
    },
  },
}

const Menu = ({ isOpen, setMenuOpen }: IMenu) => {
  const { t } = useTranslation()
  const { theme: currentTheme, updateTheme } = useTheme()
  const { logout } = useAuth()
  const location = useLocation()

  const handleChangeTheme = (value: boolean) => {
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (value) {
      updateTheme('light') //TODO change to dark after dar mode implementation
    } else {
      updateTheme('light')
    }
  }

  useEffect(() => {
    setMenuOpen(false)
  }, [location.pathname])

  return (
    <AnimatePresence>
      {isOpen && (
        <MenuWrapper
          variants={menuVariants}
          initial='closed'
          exit='closed'
          animate={isOpen ? 'open' : 'closed'}
        >
          <MenuList>
            <MenuItem>
              <Link
                to='/profile'
                onClick={() => setMenuOpen(false)}
              >
                {t('navbar.profile')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Tooltip
                content={t('common.soon')}
                placement='left'
              >
                <Switch
                  value={currentTheme === 'dark'}
                  onChange={(value: boolean) => {
                    handleChangeTheme(value)
                  }}
                  label={t('navbar.darkMode')}
                  labelPosition='top'
                />
              </Tooltip>
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout()
                setMenuOpen(false)
              }}
            >
              {t('navbar.signOut')}
            </MenuItem>
          </MenuList>
        </MenuWrapper>
      )}
    </AnimatePresence>
  )
}

export default Menu
