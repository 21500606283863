import { useRef } from 'react'
import type { AriaCheckboxProps } from 'react-aria'
import { useCheckbox } from 'react-aria'
import { useToggleState } from 'react-stately'

import { Size } from '@/shared/model/common'

import { Input, InputError, InputLabel, InputWrapper, Svg } from './InputCheckbox.styled'

interface InputCheckboxProps extends AriaCheckboxProps {
  id: string
  size?: Size
  error?: string
  rtl?: boolean
}

const InputCheckbox = (props: InputCheckboxProps) => {
  const { id, isRequired = false, size = 'md', error, children, rtl = false } = props
  const state = useToggleState(props)
  const ref = useRef(null)
  const { inputProps } = useCheckbox(props, state, ref)

  const viewBoxSwitch = (_size: Size) => {
    switch (_size) {
      case 'sm':
        return '0 0 23 23'
      case 'lg':
        return '0 -1 17 17'
      default:
        return '0 0 21 21'
    }
  }

  return (
    <InputWrapper $size={size}>
      <span>
        {rtl && (
          <InputLabel
            htmlFor={id}
            $size={size}
          >
            {children}
            {isRequired && '*'}
          </InputLabel>
        )}
        <Input
          ref={ref}
          {...inputProps}
          id={id}
          $size={size}
        />
        <Svg
          viewBox={viewBoxSwitch(size)}
          $rtl={rtl}
        >
          <polyline points='5 10.75 8.5 14.25 16 6'></polyline>
        </Svg>
        {!rtl && (
          <InputLabel
            htmlFor={id}
            $size={size}
          >
            {children}
            {isRequired && '*'}
          </InputLabel>
        )}
      </span>
      <InputError>{error}</InputError>
    </InputWrapper>
  )
}

export default InputCheckbox
