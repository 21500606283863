import { useCallback, useLayoutEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDimensions = (ref: any) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const measure = useCallback(() => {
    if (ref.current) {
      setDimensions({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      })
    }
  }, [ref])

  useLayoutEffect(() => {
    measure()

    window.addEventListener('resize', measure)
    return () => {
      window.removeEventListener('resize', measure)
    }
  }, [measure])

  return dimensions
}
