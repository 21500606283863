import { useTranslation } from 'react-i18next'

import { useGetLicensesQuery } from '@/shared/api/customersQuery'
import Skeleton from '@/shared/components/skeleton/Skeleton'
import LoadingWrapper from '@/shared/components/wrapper/LoadingWrapper'
import { PageWrapper } from '@/shared/components/wrapper/PageWrapper'
import { ILicense } from '@/shared/model/customers'
import { commonTheme } from '@/shared/theme/commonTheme'

import { ProductsWrapper } from '../products/productsElements'
import { LicenseCard } from './components/LicenseCard'
import Button from '@/shared/components/button/Button'
import { useNavigate } from '@tanstack/react-router'

const MyProducts = () => {
  const { t } = useTranslation()
  const navigate = useNavigate({ from: '/myProducts' })
  const { data: licenses, isLoading } = useGetLicensesQuery()

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Skeleton
          repeat={1}
          fullWidth
        />
        <Skeleton
          height={
            window.matchMedia(`(max-width: ${commonTheme.breakpoint.md})`).matches ? 500 : 300
          }
          width={
            window.matchMedia(`(max-width: ${commonTheme.breakpoint.md})`).matches ? 300 : 1000
          }
        />
      </LoadingWrapper>
    )
  }

  return (
    <PageWrapper>
      <h1 className='page-wrapper-gradient'>{t('products.myProducts')}</h1>
      <ProductsWrapper>
        {licenses?.map((product: ILicense, idx: number) => {
          return (
            <LicenseCard
              key={idx}
              index={idx}
              appName={product.appName}
              appUrl={product.appUrl}
              expiresAt={product.expiresAt}
              createdAt={product.createdAt}
              lastPaymentAt={product.lastPaymentAt}
              companyName={product.companyName}
              companyVatId={product.companyVatId}
              checkoutSessionId={product.checkoutSessionId}
            />
          )
        })}
        {licenses?.length === 0 && (
          <div className='my-products no-products'>
            <h2>{t('products.noProducts')}</h2>
            <Button
              label={t('products.viewProducts')}
              animations={['hover', 'tap', 'gradient']}
              size='lg'
              onClick={() => navigate({ to: '/products' })}
            />
          </div>
        )}
      </ProductsWrapper>
    </PageWrapper>
  )
}

export default MyProducts
