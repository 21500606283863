import { Variants } from 'framer-motion'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Size } from '@/shared/model/common'

import {
  DropdownArrow,
  DropdownHeader,
  DropdownInput,
  DropdownItem,
  DropdownLabel,
  DropdownList,
  DropdownNav,
  DropdownWrapper,
} from './dropdownElements'
import DropdownSelectedItem from './DropdownSelectedItem'

export interface Option {
  label: string
  value: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export interface DropdownProps {
  id: string
  name: string
  options: Option[]
  value: Option[]
  onChange: (value: Option[]) => void
  onBlur?: () => void
  error?: string
  label?: string
  placeholder?: string
  disabled?: boolean
  size?: Size
  fullWidth?: boolean
  required?: boolean
  maxItems?: number
  removeItems?: boolean
  multiselect?: boolean
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 200, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.1 } },
}

const Dropdown = ({
  id,
  name,
  options,
  value,
  onChange,
  onBlur,
  error,
  label,
  placeholder,
  disabled,
  size = 'md',
  fullWidth = false,
  required = false,
  maxItems = 8,
  removeItems = false,
  multiselect = false,
}: DropdownProps) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdownRef = useRef<any>(null)
  const dropdownListRef = useRef<HTMLSpanElement>(null)
  const inputDropdownRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [query, setQuery] = useState<string>('')
  const [optionList, setOptionList] = useState<Option[]>([...options])
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([...options])
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([])

  const notFoundValue = '_notFound!'

  useEffect(() => {
    if (options && !disabled) {
      setIsDisabled(false)
    } else if (disabled) {
      setIsDisabled(true)
    }
  }, [options, disabled])

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        onBlur?.()
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (!isOpen && !selectedOptions.length) {
      setOptionList([...options])
    }
  }, [isOpen, options, selectedOptions])

  useEffect(() => {
    if (!isOpen && selectedOptions.length && !multiselect) {
      setOptionList([...options])
    }
  }, [isOpen, multiselect, options, selectedOptions])

  useEffect(() => {
    const _filteredOptions = optionList.filter((option: Option) =>
      option.label.toLowerCase().includes(query.toLowerCase()),
    )
    if (query !== '') {
      setFilteredOptions(_filteredOptions)
      if (!_filteredOptions.length) {
        setFilteredOptions([{ label: t('common.noOptionsFound'), value: notFoundValue } as Option])
      }
    } else if (query === '') {
      setFilteredOptions(optionList)
    }

    if (!isOpen) {
      setQuery('')
    }
  }, [query, isOpen, optionList, t])

  useEffect(() => {
    const isArr = Array.isArray(value)

    if (!value || (Array.isArray(value) && value.length === 0)) {
      setSelectedOptions([])
    } else if (value && isArr) {
      setSelectedOptions(value)
    } else if (value && !isArr) {
      setSelectedOptions([value])
    }
  }, [value])

  const handleOpen = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (options && !isOpen && !isDisabled) {
      setIsOpen(true)
    } else if (
      isOpen &&
      ((e as React.KeyboardEvent<HTMLDivElement>).key === 'Escape' ||
        (e.type === 'click' && !dropdownListRef.current?.contains(e.target as Node)))
    ) {
      setIsOpen(false)
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (query === '' && e.target.value === ' ') {
      setQuery('')
    } else {
      setQuery(e.target.value)
    }
  }

  const isSelected = (option: Option) => {
    return selectedOptions.some(
      (_option: Option) => _option.value === option.value && _option.label === option.label,
    )
  }

  const handleSelect = (
    option: Option,
    e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (
      option.value === notFoundValue ||
      !option ||
      (e &&
        e.type !== 'click' &&
        e.type !== 'keypress' &&
        (e as React.KeyboardEvent<HTMLDivElement>).key !== ' ')
    ) {
      return
    }

    const idx = selectedOptions.indexOf(option)
    const idxList = optionList.indexOf(option)
    inputDropdownRef.current?.focus()
    let tmpSelectedOptions: Option[] = [...selectedOptions]
    const tmpList: Option[] = [...optionList]
    if (idx === -1) {
      if (multiselect) {
        tmpSelectedOptions.push(option)
      } else {
        tmpSelectedOptions = [option]
        setIsOpen(false)
      }

      if (removeItems) {
        tmpList.splice(idxList, 1)
        setOptionList(tmpList.sort((a, b) => a.label.localeCompare(b.label)))
      }
      if (!tmpList.length) {
        setIsOpen(false)
      }
    } else {
      if (removeItems) {
        setOptionList((prev: Option[]) =>
          [...prev, selectedOptions[idx]].sort((a, b) => a.label.localeCompare(b.label)),
        )
      }

      tmpSelectedOptions.splice(idx, 1)
    }

    onChange(tmpSelectedOptions)
  }

  return (
    <DropdownWrapper
      ref={dropdownRef}
      $isOpen={isOpen}
    >
      <DropdownLabel
        htmlFor={id}
        $size={size}
      >
        {label}
        {required && '*'}
      </DropdownLabel>
      <DropdownNav
        $id={id}
        $fullWith={fullWidth}
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
      >
        <DropdownHeader
          $fullWidth={fullWidth}
          $size={size}
          onClick={handleOpen}
          role='listbox'
          aria-expanded={isOpen}
          aria-required={required}
        >
          <>
            {!multiselect && selectedOptions.length === 1 && (
              <span>{selectedOptions[0].label}</span>
            )}
            {multiselect && (
              <span ref={dropdownListRef}>
                {selectedOptions?.map((_option: Option, index: number) => (
                  <DropdownSelectedItem
                    key={index}
                    onClick={() => handleSelect(_option)}
                    label={_option.label}
                  />
                ))}

                <DropdownInput
                  ref={inputDropdownRef}
                  id={id}
                  name={name}
                  value={query}
                  placeholder={placeholder}
                  disabled={isDisabled}
                  onChange={handleSearch}
                  autoComplete='off'
                  tabIndex={0}
                />
              </span>
            )}
          </>
          <DropdownArrow>
            <svg
              width='15'
              height='15'
              viewBox='0 0 20 20'
            >
              <path d='M0 7 L 20 7 L 10 16' />
            </svg>
          </DropdownArrow>
        </DropdownHeader>
        <DropdownList
          style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
          maxItems={maxItems}
          isOpen={isOpen}
          $fullWidth={fullWidth}
        >
          {filteredOptions?.map((option: Option, index: number) => {
            return (
              <DropdownItem
                key={index}
                variants={itemVariants}
                onClick={(
                  e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
                ) => handleSelect(option, e)}
                isSelected={isSelected(option)}
                size={size}
              >
                {option.label}
              </DropdownItem>
            )
          })}
        </DropdownList>
      </DropdownNav>
      {error}
    </DropdownWrapper>
  )
}

export default Dropdown
