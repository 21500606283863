import { queryOptions, useQuery } from '@tanstack/react-query'

import { IProductRes, IProducts } from '../model/products'
import { api } from './api'

export const useGetProductsQuery = (countryCode?: string) => {
  return useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const { data } = await api.get<IProducts>('/portal/products', {
        params: { countryCode },
      })
      return data.products
    },
  })
}

export const getProductQuery = (name: string) => {
  return queryOptions({
    queryKey: ['product', name],
    queryFn: async () => {
      const { data } = await api.get<IProductRes>(`/portal/products/${name}`)
      return data
    },
  })
}
