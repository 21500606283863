import { styled } from 'styled-components'

export const StyledOl = styled.ol`
  display: flex;
  align-items: center;
  width: 350px;
  margin-bottom: 1rem;
  padding: 0;

  @media (min-width: 640px) {
    margin-bottom: 1.25rem;
  }

  @media (max-width: ${(props) => props.theme.breakpoint.xs}) {
    width: 280px;
  }
`

export const StyledLi = styled.li<{ $active?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;

  &:after {
    content: '';
    width: 100%;
    height: 0.25rem;
    border-bottom: 0rem solid
      ${(props) =>
        props.$active ?
          props.theme.colors.primary400
        : props.theme.complementaryColors.lightGrey}; //change border width to add lines
    display: inline-block;
  }

  &:last-child:after {
    content: none;
    width: 0px;
  }

  &:last-child {
    width: 7rem; //dont ask me why 7rem, I cant figure it out
  }
`

export const Circle = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  flex-shrink: 0;
  cursor: pointer;

  @media (min-width: 1024px) {
    width: 3rem;
    height: 3rem;
  }

  background-color: ${(props) => props.theme.complementaryColors.lightGrey};

  box-shadow: ${(props) => `${props.$active ? 'inset' : ''} ${props.theme.shadows.md}`};
`

export const Icon = styled.svg<{ $active?: boolean }>`
  width: 1rem;
  height: 1rem;
  color: #2563eb;
  color: ${(props) => props.theme.colors.primary100};

  @media (min-width: 1024px) {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${(props) =>
    props.$active &&
    `
    color: ${props.theme.colors.primary100};
  `}
`
