export const themeDark = {
  colors: {
    text: '#edeff2',
    textSecondary: '#060a0e',
    background: '#060a0e',
    backgroundFields: '#060a0e',
    primary: '#78aae3',
    secondary: '#045fa4',
    accent: '#1fc3ff',
    whiteText: '#f1f5f9',
  },
  complementaryColors: {
    success: '#00b158', // toast success
    error: '#ff2c2c', // toast error
    warning: '#fff23d', // toast warning
    info: '#1c4e87', // toast info
    enabled: '#00e759', // switch enable background
    disabled: '#eeeded', // disabled eg. button
    disabledText: '#eeeded',
    placeholder: '#797775',
    toastBackground: '#1b1b1b',
  },
}
