import { useMutation } from '@tanstack/react-query'

import { api } from './api'

export interface ICheckoutSessionReq {
  customerId: string
  productId: string
  paymentMethodTypes: string[]
}

export interface ICheckoutSessionRes {
  customerId: string
  url: string
}

export const useCheckoutMutation = () => {
  return useMutation({
    mutationKey: ['checkout'],
    mutationFn: async ({ customerId, productId, paymentMethodTypes }: ICheckoutSessionReq) => {
      const { data } = await api.post<ICheckoutSessionRes>(`/payments/checkout-session`, {
        customerId,
        productId,
        paymentMethodTypes,
      })

      return data
    },
  })
}
