/* eslint-disable camelcase */
/* eslint-disable sonarjs/no-duplicate-string */
import { t } from 'i18next'
import { z } from 'zod'

export const companyNameValidator = z.string().min(1, t('common.validator.requiredField'))

export const companyTagValidator = z
  .string()
  .min(1, t('common.validator.requiredField'))
  .min(3, t('register.validator.minCompanyTag'))

export const companyVatIdValidator = z
  .string()
  .min(1, t('common.validator.requiredField'))
  .max(10, t('register.validator.maxVatId'))

export const firstNameValidator = z.string().min(1, t('common.validator.requiredField'))

export const lastNameValidator = z.string().min(1, t('common.validator.requiredField'))

export const emailValidator = z.string().email(t('common.validator.validateEmail'))

export const phoneNumberValidator = z
  .string()
  .regex(
    new RegExp(/^\+\d{1,3}\s?\d{1,4}[\s.-]?\d{3}[\s.-]?\d{3,4}$/),
    t('common.validator.validatePhone'),
  )

export const passwordValidator = z.string().min(8, t('register.validator.minPasswd'))

export const confirmPasswordValidator = z.string()

export const termsValidator = z.boolean({
  required_error: t('register.validator.requiredTerms'),
})

export const registrationSchema = z
  .object({
    companyName: companyNameValidator,
    companyTag: companyTagValidator,
    companyVatId: companyVatIdValidator,
    firstName: firstNameValidator,
    lastName: lastNameValidator,
    email: emailValidator,
    phoneNumber: phoneNumberValidator,
    password: passwordValidator,
    confirmPassword: confirmPasswordValidator,
    terms: termsValidator,
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: t('register.validator.passwordsDoNotMatch'),
  })

export const validateConflicts = (msg: string) => {
  if (msg.includes('email')) {
    return 'email'
  } else if (msg.includes('tag')) {
    return 'tag'
  }
  return 'error'
}
