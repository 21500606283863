import { _PageWrapper } from './pageWrapperElements'

export const PageWrapper = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  return (
    <_PageWrapper
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.6,
          staggerChildren: 0.1,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </_PageWrapper>
  )
}
