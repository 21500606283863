import { motion } from 'framer-motion'
import { styled } from 'styled-components'

import { Size } from '@/shared/model/common'

export const SwitchWrapper = styled.div<{ $size: Size; $labelPosition: 'left' | 'top' }>`
  > span:first-of-type {
    display: flex;
    align-items: center;
    flex-direction: ${(props) => (props.$labelPosition === 'top' ? 'column' : 'unset')};
    gap: 0.5rem;
    ${(props) => props.$size === 'sm' && `font-size: ${props.theme.fontSize.smd};`}
    ${(props) => props.$size === 'lg' && `font-size: ${props.theme.fontSize.lmd};`}
  }

  > span:last-of-type {
    color: ${(props) => props.theme.complementaryColors.error};
    font-size: ${(props) => props.theme.fontSize.smd};
    height: ${(props) => props.theme.fontSize.smd};
  }
`

export const SwitchHandleWrapper = styled.div<{ $isOn: boolean; $size: Size }>`
  width: 38px;
  height: 18px;
  display: flex;
  border-radius: 12px;
  padding: 2px 3px;
  cursor: pointer;
  align-items: center;
  transition: background-color 0.2s ease-in-out;

  ${(props) => (props.$isOn ? 'justify-content: flex-end' : 'justify-content: flex-start;')};

  background-color: ${(props) =>
    props.$isOn ?
      props.theme.complementaryColors.enabled
    : props.theme.complementaryColors.placeholder};

  ${(props) => props.$size === 'sm' && `height: 14px; width: 30px`}
  ${(props) => props.$size === 'lg' && `height: 22px; width: 46px`}
`

const _SwitchHandle = styled(motion.div)<{ $size: Size }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.complementaryColors.disabled};
  border-radius: 40px;

  ${(props) => props.$size === 'sm' && `height: 12px; width: 12px`}
  ${(props) => props.$size === 'lg' && `height: 20px; width: 20px`}
`

export const SwitchHandle = ({ $size }: { $size: Size }) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  }
  return (
    <_SwitchHandle
      $size={$size}
      layout
      transition={spring}
    />
  )
}
