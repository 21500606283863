export const register = {
  registerTitle: 'Sign up',
  nextStep: 'Next',
  goToSignIn: 'Sign In',
  companyName: 'Company Name',
  enterCompanyName: 'Enter company name...',
  customerTag: 'Company Tag',
  enterCustomerTag: 'Enter company tag...',
  companyVatId: 'Tax ID',
  enterCompanyVatId: 'Enter Tax ID...',
  firstName: 'First Name',
  enterFirstName: 'Enter your first name...',
  lastName: 'Last Name',
  enterLastName: 'Enter your last name...',
  email: 'E-mail',
  enterEmail: 'Enter e-mail...',
  phoneNumber: 'Phone Number',
  enterPhoneNumber: '+48 573 943 997',
  password: 'Password',
  enterPassword: 'Enter password...',
  confirmPassword: 'Confirm Password',
  enterConfirmPassword: 'Enter password again...',
  terms: 'I accept the terms',
  successMsg:
    'We sent a confirmation link to the e-mail address you provided. You need to access it in order to complete the registration.',
  btnRegister: 'Sign up',
  toast: {
    success: 'Successfully sent data for registration',
    error: 'An error occurred during registration',
    email: 'This e-mail is already taken',
    tag: 'This company tag is already taken',
  },
  validator: {
    minCompanyTag: 'Minimum 3 characters are required',
    maxVatId: 'Maximum length is 10 characters',
    minPasswd: 'Minimum 8 characters are required',
    samePassword: 'Passwords must be the same',
    passwordsDoNotMatch: 'Passwords must be the same',
    requiredTerms: 'Acceptance of terms is required',
  },
}
