/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { styled } from 'styled-components'

import { Size } from '@/shared/model/common'

export const DropdownWrapper = styled.div<{ $isOpen: boolean }>`
  border: 0;
  position: relative;
  margin-bottom: 15px;
  ${(props) => props.$isOpen && `z-index: 100`}
`

export const DropdownLabel = styled.label<{ $size: string }>`
  margin-bottom: 4px;
  margin-left: 2px;
  color: ${(props: any) => props.theme.colors.text};
  ${(props) => props.$size === 'sm' && `font-size: ${props.theme.fontSize.smd};`}
  ${(props) => props.$size === 'lg' && `font-size: ${props.theme.fontSize.lmd};`}
`

export const DropdownNav = styled(motion.nav)<{ $fullWith: boolean; $id: string }>`
  filter: drop-shadow(1px 1px 1px #5f5f5f);
  ${(props) =>
    props.$fullWith ? `width: 100%`
    : props.$id === 'langSelector' ? `width: 200px`
    : `width: 300px`}
`

const _DropdownHeader = styled(motion.div)<{ $fullWith: boolean; $size: Size }>`
  display: flex;
  background: ${(props: any) => props.theme.colors.background};
  border-radius: 10px;
  align-items: center;

  > span {
    max-width: calc(100% - 60px);
    max-width: ${(props) => props.$size === 'sm' && 'calc(100% - 52px)'};
    max-width: ${(props) => props.$size === 'lg' && 'calc(100% - 58px)'};
    width: 100%;
    gap: 4px;
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    font-size: ${(props) => props.$size === 'sm' && props.theme.fontSize.smd};
    font-size: ${(props) => props.$size === 'lg' && props.theme.fontSize.lmd};
    padding: ${(props) => props.$size === 'sm' && '4px'};
    padding: ${(props) => props.$size === 'lg' && '10px'};
    padding-right: 6px;
  }
`

export const DropdownHeader = ({
  children,
  onClick,
  role,
  $fullWidth,
  $size,
}: {
  children: JSX.Element[] | JSX.Element
  onClick: (e: any) => void
  role: string
  $fullWidth: boolean
  $size: Size
}) => (
  <_DropdownHeader
    whileTap={{ scale: 0.97 }}
    onClick={onClick}
    onKeyDown={onClick}
    role={role}
    tabIndex={-1}
    $fullWith={$fullWidth}
    $size={$size}
  >
    {children}
  </_DropdownHeader>
)

export const DropdownInput = styled(motion.input)`
  background: transparent;
  color: #000;
  border: 0;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  flex: 1 1 75px;
`

const _DropdownArrow = styled(motion.div)`
  width: 15px;
  height: 15px;
  position: relative;
  right: -15px;
  margin-top: auto;
  margin-bottom: auto;
`

export const DropdownArrow = ({ children }: { children: JSX.Element }) => (
  <_DropdownArrow
    variants={{
      open: { rotate: 180 },
      closed: { rotate: 0 },
    }}
    transition={{ duration: 0.2 }}
    style={{ originY: 0.55 }}
  >
    {children}
  </_DropdownArrow>
)

const _DropdownList = styled(motion.ul)<{ $maxItems: number; $fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${(props: any) => props.theme.colors.background};
  list-style: none;
  margin-top: 10px;
  padding: 0;
  max-height: ${(props: any) => `calc(${props.$maxItems} * 40px)`};
  overflow: hidden auto;
  max-width: ${(props) => (props.$fullWidth ? `unset` : `300px`)};
  width: 100%;
  position: absolute;
`

export const DropdownList = ({
  children,
  style,
  maxItems,
  isOpen,
  $fullWidth,
}: {
  children: JSX.Element[] | JSX.Element
  style: object
  maxItems: number
  isOpen: boolean
  $fullWidth: boolean
}) => (
  <AnimatePresence>
    {isOpen && (
      <_DropdownList
        $fullWidth={$fullWidth}
        layout='position'
        initial={{
          clipPath: 'inset(10% 50% 90% 50% round 10px)',
        }}
        animate={{
          clipPath: 'inset(0% 0% 0% 0% round 10px)',
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.4,
            delayChildren: 0.2,
            staggerChildren: 0.05,
          },
        }}
        exit={{
          clipPath: 'inset(10% 50% 90% 50% round 10px)',
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.4,
            delayChildren: 0.2,
            staggerChildren: 0.05,
          },
        }}
        style={style}
        $maxItems={maxItems}
        tabIndex={-1}
        role='listbox'
        aria-multiselectable='true'
      >
        {children}
      </_DropdownList>
    )}
  </AnimatePresence>
)

const _DropdownItem = styled(motion.li)<{ $isSelected: boolean; $size: Size }>`
  list-style: none;
  margin: 0;
  padding: 10px;
  display: block;
  cursor: pointer;
  font-size: 1rem;
  height: 20px;
  background-color: ${(props: any) => (props.$isSelected ? '#c9c8c8' : 'unset')};
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  min-height: 18px;
  user-select: none;
  -webkit-touch-callout: none;
  font-size: ${(props) => props.$size === 'sm' && props.theme.fontSize.smd};
  font-size: ${(props) => props.$size === 'lg' && props.theme.fontSize.lmd};

  &:hover {
    background-color: #e0e0e0;
    transition: background-color 0.15s;
  }
`

export const DropdownItem = ({
  children,
  variants,
  onClick,
  isSelected,
  size,
}: {
  children: string
  variants: Variants
  onClick: (e: any) => void
  isSelected: boolean
  size: Size
}) => (
  <_DropdownItem
    id={children}
    layout
    variants={variants}
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    role='option'
    aria-selected={isSelected}
    onClick={onClick}
    onKeyDown={onClick}
    aria-label={children}
    $isSelected={isSelected}
    title={children}
    tabIndex={0}
    $size={size}
  >
    {children}
  </_DropdownItem>
)

export const DropdownSelectedItemWrapper = styled(motion.span)`
  display: flex;
  background-color: #e7e7e7;
  flex: 1 1 60px;
  height: 20px;
  justify-content: space-between;
  text-overflow: ellipsis;
  align-items: center;
  border-radius: 8px;
  padding: 2px 5px;
  cursor: pointer;
  max-width: 250px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
  }
`
