/* eslint-disable @typescript-eslint/no-explicit-any */
import { commonTheme } from './commonTheme'
import { themeDark } from './themeDark'
import { themeLight } from './themeLight'

interface ITheme {
  dark: any
  light: any
}

const mergeDeep = (target: any, source: any) => {
  const output = { ...target }
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (key in target) {
          output[key] = mergeDeep(target[key], source[key])
        } else {
          output[key] = source[key]
        }
      } else {
        output[key] = source[key]
      }
    }
  }
  return output
}

export const theme: ITheme = {
  dark: {
    ...mergeDeep(themeDark, commonTheme),
  },
  light: {
    ...mergeDeep(themeLight, commonTheme),
  },
}
