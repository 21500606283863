import { useRef } from 'react'
import { useButton } from 'react-aria'
import type { ButtonProps } from 'react-aria-components'

import { Size } from '@/shared/model/common'

import { ButtonStyled } from './buttonElements'

type ButtonAnimations = 'hover' | 'tap' | 'gradient'

export interface IButton extends ButtonProps {
  label: string
  btnType?: 'primary' | 'secondary'
  isDisabled?: boolean
  fullWidth?: boolean
  onClick?: () => void
  size?: Size
  iconStart?: JSX.Element | JSX.Element[]
  iconEnd?: JSX.Element | JSX.Element[]
  pill?: number
  animations?: ButtonAnimations[]
}

const Button = (props: IButton) => {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)

  const {
    label,
    btnType = 'primary',
    isDisabled = false,
    fullWidth = false,
    onClick,
    size = 'md',
    iconStart,
    iconEnd,
    pill = 1,
    animations,
  } = props

  return (
    //@ts-expect-error Conflict of interfaces
    <ButtonStyled
      ref={ref}
      btnType={btnType}
      onClick={onClick}
      fullWidth={fullWidth}
      size={size}
      disabled={isDisabled}
      isDisabled={isDisabled}
      pill={pill}
      animations={animations}
      {...buttonProps}
      layout
      animate={
        !isDisabled && animations?.includes('gradient') ?
          { backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'] }
        : {}
      }
      transition={
        !isDisabled && animations?.includes('gradient') ?
          {
            backgroundPosition: { duration: 8, ease: 'easeInOut', repeat: Infinity },
          }
        : {}
      }
      whileHover={
        !isDisabled && animations?.includes('hover') ?
          { scale: 0.95, transition: { duration: 0.1, repeat: 0, ease: 'easeInOut' } }
        : {}
      }
      whileTap={
        !isDisabled && animations?.includes('tap') ?
          { scale: 0.9, transition: { duration: 0.1, repeat: 0, ease: 'easeInOut' } }
        : {}
      }
    >
      {iconStart}
      {label}
      {iconEnd}
    </ButtonStyled>
  )
}

export default Button
