import { motion } from 'framer-motion'

export interface IStaggerText {
  text: string
  split?: string
  delay?: number
  staggerSpeed?: number
  repeatDelay?: number
  delayDivider?: number
}

const StaggerText = ({
  text,
  split = ' ',
  delay = 0,
  staggerSpeed = 0.25,
  delayDivider = 10,
}: IStaggerText) => {
  const textSplit = text.split(split)

  return (
    <>
      {textSplit.map((el: string, idx: number) => (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: staggerSpeed,
            delay: delay + idx / delayDivider,
          }}
          key={idx}
        >
          {el}
          {split}
        </motion.span>
      ))}
    </>
  )
}

export default StaggerText
