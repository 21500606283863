import { createContext } from 'react'

import { IAuth } from './auth'
import { ICustomer } from './shared/model/customers'

export interface IAuthContext {
  isAuthenticated: boolean
  login: (user: IAuth) => Promise<void>
  logout: () => void
  updateUser: (user: ICustomer) => void
  user: IAuth | null
  decodedUser: ICustomer | undefined
}

const AuthContext = createContext<IAuthContext | null>(null)

export default AuthContext
