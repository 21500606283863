/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from 'styled-components'

import { Size } from '@/shared/model/common'

import { InputErrorDefault, InputLabelDefault, InputLabelFloatDefault } from './InputDefault.styled'

export const InputWrapper = styled.div<{
  $fullWidth: boolean
  $floatLabel: boolean
  $size: Size
}>`
  display: flex;
  flex-direction: column;
  max-width: ${(props: any) => (props.$fullWidth ? 'unset' : '300px')};
  margin-top: 12px;
  margin-bottom: 12px;
  position: ${(props: any) => (props.$floatLabel ? 'relative' : 'unset')};
  width: 100%;

  & input:focus + label,
  input:not(:placeholder-shown) + label {
    top: 0;
    font-size: 0.8rem;
    color: ${(props: any) => props.theme.colors.text};
    padding: 7px;
  }

  min-height: ${(props) =>
    `calc(${props.theme.fontSize.smd} + 6px + ${props.theme.fontSize.smd} + 50px)`};
  ${(props) =>
    props.$size === 'sm' &&
    `min-height: calc(${props.theme.fontSize.smd} + 6px + ${props.theme.fontSize.smd});`}
  ${(props) => props.$size === 'lg' && `min-height: ${props.theme.fontSize.lmd};`}
`

export const InputLabelFloat = styled(InputLabelFloatDefault)``

export const Input = styled.input<{ $disabled: boolean; $floatLabel: boolean; $size: Size }>`
  height: 24px;
  padding: 12px 16px;
  font-size: ${(props: any) => props.theme.fontSize.md};
  color: ${(props: any) => props.theme.colors.text};
  background-color: ${(props: any) => props.theme.colors.backgroundFields};
  border: 0;
  border-radius: ${(props: any) => props.theme.borderRadius[props.$size]};
  box-shadow: ${(props: any) => props.theme.shadows.md};
  transition: all 0.15s;
  ${(props) =>
    props.$size === 'sm' &&
    `height: 18px; padding: 8px 16px; font-size: ${props.theme.fontSize.smd};`}

  ${(props) =>
    props.$size === 'lg' &&
    `height: 32px; padding: 14px 16px; font-size: ${props.theme.fontSize.lmd};`}

  &::placeholder {
    opacity: ${(props: any) => (props.$floatLabel ? 0 : 'unset')};
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: ${(props: any) => props.theme.shadows.lg};
  }

  &[disabled] {
    box-shadow: ${(props: any) => props.theme.shadows.md};
    cursor: not-allowed;
    background-color: ${(props: any) => props.theme.complementaryColors.disabled};

    & + label {
      cursor: not-allowed;
      color: ${(props: any) => props.theme.complementaryColors.disabled};
    }
  }
`

export const InputError = styled(InputErrorDefault)``

export const InputLabel = styled(InputLabelDefault)``
