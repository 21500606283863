import { Link } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CgProfile } from 'react-icons/cg'

import getTheme from '@/shared/utils/getTheme'

import LogoText from '@assets/logo_text.webp'
import Menu from './menu/Menu'
import { NavbarLogo, NavbarMenu, NavbarWrapper } from './NavbarElements'

const Navbar = () => {
  const { t } = useTranslation()
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const theme = getTheme()

  useEffect(() => {
    setMenuOpen(false)
  }, [window.location.href])

  return (
    <>
      <Menu
        isOpen={isMenuOpen}
        theme={theme}
        setMenuOpen={setMenuOpen}
      />
      <NavbarWrapper>
        <NavbarLogo to='/'>
          <img
            src={LogoText}
            alt='logo'
          />
        </NavbarLogo>
        <NavbarMenu>
          <Link to='/products'>{t('navbar.products')}</Link>
          <Link to='/myProducts'>{t('navbar.myProducts')}</Link>
          <motion.span whileTap={{ scale: 0.95 }}>
            <CgProfile onClick={() => setMenuOpen((prev) => !prev)} />
          </motion.span>
        </NavbarMenu>
      </NavbarWrapper>
    </>
  )
}

export default Navbar
