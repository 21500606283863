import { motion, Variants } from 'framer-motion'
import { styled } from 'styled-components'

export const MenuWrapper = styled(motion.div)`
  position: absolute;
  top: calc(32px + 2rem + 52px);
  right: 2.5dvw;
  width: 250px;
  height: 180px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  box-shadow: ${(props) => props.theme.shadows.md};
  z-index: 100;
  background: ${(props) => props.theme.colors.bgGlass};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  overflow: hidden;
`

export const MenuList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.borderRadius.md};
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden auto;
  max-width: 250px;
  width: 100%;
  position: absolute;
  overflow: hidden;
`

const _MenuItem = styled(motion.li)`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  height: 40px;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  min-height: 18px;
  user-select: none;
  -webkit-touch-callout: none;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
    transition: background-color 0.15s;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
  }
`

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 24,
    },
  },
  closed: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.1,
      type: 'spring',
      stiffness: 200,
      damping: 24,
    },
  },
}

export const MenuItem = ({
  children,
  onClick,
}: {
  children: JSX.Element | JSX.Element[] | string
  onClick?: () => void
}) => (
  <_MenuItem
    variants={itemVariants}
    onClick={onClick}
    aria-hidden='true'
  >
    {children}
  </_MenuItem>
)
