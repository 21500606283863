import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useAuth } from '@/auth'
import { useCheckoutMutation } from '@/shared/api/paymentsQuery'
import { useGetProductsQuery } from '@/shared/api/productsQuery'
import Skeleton from '@/shared/components/skeleton/Skeleton'
import LoadingWrapper from '@/shared/components/wrapper/LoadingWrapper'
import { PageWrapper } from '@/shared/components/wrapper/PageWrapper'
import { IProduct } from '@/shared/model/products'

import { ProductCard } from './components/ProductCard'
import { ProductsWrapper } from './productsElements'
import useDimBreakpoints from '@/shared/utils/useDimBreakpoints'

const Products = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const { md } = useDimBreakpoints()
  const { data: products, isLoading } = useGetProductsQuery('pl')
  const { mutateAsync: checkoutMutation } = useCheckoutMutation()

  const handleCheckout = (productId: string) => {
    if (!auth.decodedUser) throw new Error('No user')
    return toast.promise(
      checkoutMutation({
        customerId: auth.decodedUser?.id,
        productId,
        paymentMethodTypes: ['card'],
      }).then((res) => {
        window.location.href = res.url
      }),
      {
        pending: t('products.toast.pending'),
        success: t('products.toast.success'),
        error: t('products.toast.checkoutErr'),
      },
    )
  }

  if (isLoading || !products) {
    return (
      <LoadingWrapper>
        <Skeleton
          repeat={1}
          fullWidth
        />
        <Skeleton
          height={md ? 500 : 300}
          width={md ? 300 : 1000}
        />
      </LoadingWrapper>
    )
  }

  return (
    <PageWrapper>
      <h1 className='page-wrapper-gradient'>{t('products.title')}</h1>
      <ProductsWrapper>
        {products.map((product: IProduct, idx: number) => {
          return (
            <ProductCard
              key={idx}
              index={idx}
              id={product.id}
              name={product.name}
              description={product.shortDescription}
              price={product.prize}
              onCheckout={handleCheckout}
            />
          )
        })}
      </ProductsWrapper>
    </PageWrapper>
  )
}

export default Products
