import { ReactNode, useCallback, useContext, useEffect, useState } from 'react'

import AuthContext from './AuthContext'
import { router } from './main'
import { ICustomer } from './shared/model/customers'
import { AUTH_LS } from './config'
import useAsyncState from './shared/utils/useAsyncState'

export interface IAuth {
  email: string
  sessionToken: string
  refreshToken: string
}

export const getStoredUser = () => {
  const _user = localStorage.getItem(AUTH_LS)
  if (_user) {
    return JSON.parse(_user) as IAuth
  }
  return null
}

export const setStoredUser = (user: IAuth | null) => {
  if (user) {
    localStorage.setItem(AUTH_LS, JSON.stringify(user))
  } else {
    localStorage.removeItem(AUTH_LS)
  }
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useAsyncState<IAuth | null>(getStoredUser())
  const [decodedUser, setDecodedUser] = useState<ICustomer | undefined>()
  const isAuthenticated = !!user?.sessionToken

  const logout = useCallback(() => {
    setStoredUser(null)
    setUser(null)
    router.invalidate().finally(() => {
      router.navigate({ to: '/login' })
    })
  }, [])

  const login = useCallback(async (_user: IAuth) => {
    await setUser(_user)
    setStoredUser(_user)
  }, [])

  const updateUser = useCallback((_user: ICustomer) => {
    setDecodedUser(_user as ICustomer)
  }, [])

  useEffect(() => {
    const _user = getStoredUser()
    if (_user) {
      setUser(_user)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        user,
        login,
        logout,
        updateUser,
        decodedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
