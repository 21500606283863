import { Link } from '@tanstack/react-router'
import { styled } from 'styled-components'

export const NavbarWrapper = styled.div`
  display: flex;
  width: 90dvw;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 16px;
  left: calc(5dvw - 2rem);
  background-color: ${(props) => props.theme.colors.primary400};
  padding: 1rem;
  padding-inline: 2rem;
  border-radius: ${(props) => props.theme.borderRadius.md};
  margin-bottom: 3rem;
  box-shadow: ${(props) => props.theme.shadows.md};
`

export const NavbarLogo = styled(Link)`
  display: flex;
  text-decoration: none;

  img {
    width: auto;
    height: 50px;
  }

  h1 {
    color: ${(props) => props.theme.colors.whiteText};
    font-weight: 300;
    margin-bottom: 2px;
    margin-top: auto;
    margin-left: 16px;
  }
`

export const NavbarMenu = styled.div`
  display: flex;
  column-gap: 2rem;
  align-items: center;
  color: ${(props) => props.theme.colors.whiteText};

  > span > svg {
    width: 3rem;
    height: auto;
    color: ${(props) => props.theme.colors.whiteText};
    cursor: pointer;
  }

  > a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.whiteText};
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSize.lmd};
  }
`
