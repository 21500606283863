import LogoText from '@assets/logo_text_blue.webp'
import { NavbarMobileLogoStyled } from './NavbarMobileElements'

const NavbarMobileLogo = () => {
  return (
    <NavbarMobileLogoStyled to='/'>
      <img
        src={LogoText}
        alt='logo'
      />
    </NavbarMobileLogoStyled>
  )
}

export default NavbarMobileLogo
